import React, { useState } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import {
  ItemMessagesListMessageFileFileAttachment,
  ItemMessagesListMessageFileMessage
} from './ItemMessagesListMessageFile.types';
import { FetchMessagesCacheKey } from '../../../../../messagesTypes';

import { MessagesListMessageFileAttachmentButtons } from '../../../MessagesListMessage/buttons/MessagesListMessageFileAttachmentButtons';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { Icon } from '../../../../../../../helpers/Icon';
import { Tooltip } from '../../../../../../../helpers/tooltips/Tooltip';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';
import { TooltipSingletonTarget } from '../../../../../../../helpers/tooltips/TooltipSingletonTarget';
import { LinkHelper } from '../../../../../../../helpers/links/LinkHelper';
import { SizeHelper } from '../../../../../../../helpers/SizeHelper';
import { Translate } from '../../../../../../../helpers/Translate';

import { getFileIcon } from '../../../../../../../utils/getFileIcon';

import { words } from '../../../../../../../locales/keys';

import { MessagesPermissions } from '../../../../../messagesConstants';

interface ItemMessagesListMessageFileProps {
  messagesCacheKey?: FetchMessagesCacheKey;
  fileAttachment: ItemMessagesListMessageFileFileAttachment;
  message: ItemMessagesListMessageFileMessage;
  isVisibleForClient: boolean;
  tooltipSingleton?: boolean;
}

function ItemMessagesListMessageFile({
  messagesCacheKey,
  fileAttachment,
  message,
  isVisibleForClient,
  tooltipSingleton
}: ItemMessagesListMessageFileProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLSpanElement | null>(null);

  const tooltipView = fileAttachment.name ? (
    <Tooltip
      placement={TooltipPlacement.TOP}
      referenceElement={referenceTooltipElement}
      className="break-words"
      withArrow
    >
      <div className="break-words">{fileAttachment.name}</div>
    </Tooltip>
  ) : null;

  const tooltipSingletonView = fileAttachment.name ? (
    <TooltipSingletonTarget referenceElement={referenceTooltipElement}>
      <div className="break-words">{fileAttachment.name}</div>
    </TooltipSingletonTarget>
  ) : null;

  return (
    <div
      className="group/item flex-grow max-w-60 relative"
      ref={setReferenceTooltipElement}
    >
      <div className="flex gap-1 relative h-full rounded bg-gray-100 dark:bg-gray-850 hover:bg-gray-200 dark:hover:bg-gray-800 p-2 pr-3 focus-within:ring-base focus-within:ring-offset-0 overflow-hidden transition-colors duration-150 ease-out">
        <div className="flex flex-col justify-between items-center">
          <Icon
            icon={getFileIcon(fileAttachment.file)}
            className="h-5 w-5 -mt-0.5"
          />

          <CheckPermissions
            action={MessagesPermissions.READ_MESSAGE_VISIBLE_FOR_CLIENT_ICON}
          >
            {isVisibleForClient && (
              <Icon
                icon={IconsEnum.EYE_SOLID}
                className="h-3 w-3 text-gray-500 mb-0.5 group-hover/item:hidden"
              />
            )}
          </CheckPermissions>

          <Icon
            icon={IconsEnum.DOWNLOAD_SOLID}
            className="h-3 w-3 text-gray-500 mb-0.5 hidden group-hover/item:block"
          />
        </div>

        <div className="flex flex-col justify-between gap-1.5">
          <LinkHelper
            className="flex break-all line-clamp-3 leading-4 text-xs font-medium focus:outline-none"
            href={fileAttachment.file}
            target="_blank"
          >
            {fileAttachment.name}
            <span className="absolute inset-0" aria-hidden="true" />
          </LinkHelper>
          <div className="text-gray-600 dark:text-gray-400 text-xs relative pointer-events-none">
            <div className="group-hover/item:opacity-0">
              <SizeHelper size={fileAttachment.size} />
              {fileAttachment.ext ? (
                <span> &ndash; {fileAttachment.ext}</span>
              ) : null}
            </div>
            <div className="absolute inset-0 hidden group-hover/item:block">
              <Translate id={words.download} />
            </div>
          </div>
        </div>
      </div>
      <MessagesListMessageFileAttachmentButtons
        addClassName="bottom-0"
        messagesCacheKey={messagesCacheKey}
        fileAttachment={fileAttachment}
        message={message}
      />
      {tooltipSingleton ? tooltipSingletonView : tooltipView}
    </div>
  );
}

export default ItemMessagesListMessageFile;
