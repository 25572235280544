import React from 'react';
import some from 'lodash/some';
import compact from 'lodash/compact';

import { PositionStyleEnum } from '../../../../../../../../../types';
import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';
import {
  ItemMessagesListMessageImageFileAttachmentButtonsAttachment,
  ItemMessagesListMessageImageFileAttachmentButtonsMessage
} from './ItemMessagesListMessageImageFileAttachmentButtons.types';

import { useCurrentUser } from '../../../../../../../../../auth/hooks/useAuth';

import { DownloadFileAttachmentModalButton } from '../../../../../../../../fileAttachments/components/buttons/DownloadFileAttachmentModalButton';
import { DeleteFileAttachmentButton } from '../../../../../../../../fileAttachments/components/buttons/DeleteFileAttachmentButton';
import { EditImageAndSendToTaskModalButton } from '../../../../../../../../tasks/components/modalButtons/EditImageAndSendToTaskModalButton';
import { EditImageAndSendToProjectModalButton } from '../../../../../../../../projects/components/modalButtons/EditImageAndSendToProjectModalButton';
import { SubmitSourceFilesToTaskModalButton } from '../../../../../../../../tasks/components/modalButtons/SubmitSourceFilesToTaskModalButton';

import { TogglePreventModalCloseAction } from '../../../../../../../../../helpers/modals/modalsTypes';
import { TooltipDropdownHelper } from '../../../../../../../../../helpers/dropdowns/TooltipDropdownHelper';
import { PopoverPlacement } from '../../../../../../../../../helpers/Popover/popoverConstants';

import { TooltipSingletonSourceWrapper } from '../../../../../../../../../helpers/tooltips/TooltipSingletonSourceWrapper';
import { TooltipPlacement } from '../../../../../../../../../helpers/tooltips/tooltipsConstants';

import { MessagesPermissions } from '../../../../../../../messagesConstants';
import { words, tasksKeys } from '../../../../../../../../../locales/keys';

interface ItemMessagesListMessageImageFileAttachmentButtonsProps {
  message: ItemMessagesListMessageImageFileAttachmentButtonsMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  attachment: ItemMessagesListMessageImageFileAttachmentButtonsAttachment;
  togglePreventModalClose?: TogglePreventModalCloseAction;
}

function ItemMessagesListMessageImageFileAttachmentButtons({
  message,
  messagesCacheKey,
  attachment,
  togglePreventModalClose
}: ItemMessagesListMessageImageFileAttachmentButtonsProps) {
  const currentUser = useCurrentUser();

  const withEditImageButton =
    Boolean(message.task?.nanoId || message.project?.nanoId) &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_FILE_ATTACHMENT_EDIT_BUTTON
    );

  const withDownloadButton =
    attachment.uuid &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_DOWNLOAD_FILE_ATTACHMENT_BUTTON
    );

  const withDeleteButton =
    attachment.uuid &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_DELETE_FILE_ATTACHMENT_BUTTON
    );

  const withSubmitSourceFilesButton =
    attachment.uuid &&
    message.task?.nanoId &&
    some(
      compact([
        MessagesPermissions.READ_MESSAGE_FILE_ATTACHMENT_SUBMIT_SOURCE_FILES_BUTTON,
        currentUser.nanoId === message.user.nanoId
          ? MessagesPermissions.READ_SELF_MESSAGE_FILE_ATTACHMENT_SUBMIT_SOURCE_FILES_BUTTON
          : null
      ]),
      (action) => currentUser.hasPermissions(action)
    );

  if (
    !withEditImageButton &&
    !withDownloadButton &&
    !withDeleteButton &&
    !withSubmitSourceFilesButton
  ) {
    return null;
  }

  return (
    <div className="absolute top-2 right-2 opacity-0 group-hover/item:opacity-100 bg-white dark:bg-gray-800 rounded-md p-0.5 flex gap-0.5 shadow">
      <TooltipSingletonSourceWrapper placement={TooltipPlacement.TOP} withArrow>
        {withEditImageButton &&
        message.task?.nanoId &&
        !message.project?.nanoId ? (
          <EditImageAndSendToTaskModalButton
            file={attachment.file}
            taskNanoId={message.task.nanoId}
            className="py-1 pl-1 pr-1 rounded-md bg-white dark:bg-gray-800 leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            iconClassName="h-5 w-5"
            icon={IconsEnum.PENCIL_ALT_OUTLINE}
            tooltipI18nText={words.edit}
            toggleImageEditorBackdrop={togglePreventModalClose}
            tooltipPlacement={TooltipPlacement.TOP}
            tooltipSingleton
          />
        ) : null}
        {withEditImageButton &&
        !message.task?.nanoId &&
        message.project?.nanoId ? (
          <EditImageAndSendToProjectModalButton
            file={attachment.file}
            projectNanoId={message.project.nanoId}
            className="py-1 pl-1 pr-1 rounded-md bg-white dark:bg-gray-800 leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            iconClassName="h-5 w-5"
            icon={IconsEnum.PENCIL_ALT_OUTLINE}
          />
        ) : null}
        <TooltipDropdownHelper
          buttonClassName="py-1 pl-1 pr-1 rounded-md bg-white dark:bg-gray-800 leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
          icon={IconsEnum.DOTS_HORIZONTAL_OUTLINE}
          tooltipSingleton
          popoverPositionStyle={PositionStyleEnum.fixed}
          tooltipI18nText={words.more}
          dropdownPlacement={PopoverPlacement.BOTTOM_END}
        >
          {withDownloadButton && (
            <DownloadFileAttachmentModalButton
              fileAttachmentUuid={attachment.uuid}
              className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
              i18nText={words.download}
            />
          )}
          {withSubmitSourceFilesButton && message.task?.nanoId ? (
            <SubmitSourceFilesToTaskModalButton
              taskNanoId={message.task.nanoId}
              className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
              i18nText={tasksKeys.submitAsSourceFile}
              initialFile={attachment}
            />
          ) : null}
          {withDeleteButton && (
            <DeleteFileAttachmentButton
              cacheKey={messagesCacheKey}
              fileAttachmentUuid={attachment.uuid}
              className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
              i18nText={words.remove}
            />
          )}
        </TooltipDropdownHelper>
      </TooltipSingletonSourceWrapper>
    </div>
  );
}

export default ItemMessagesListMessageImageFileAttachmentButtons;
