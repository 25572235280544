import React from 'react';
import isEmpty from 'lodash/isEmpty';
import cl from 'classnames';

import { TogglePreventModalCloseAction } from '../../../../../../../helpers/modals/modalsTypes';
import { ItemMessagesListDefaultMessageItem } from './ItemMessagesListDefaultMessage.types';
import {
  FetchMessagesCacheKey,
  MessageProjectUUID,
  MessageTaskUUID
} from '../../../../../messagesTypes';

import { ItemMessagesListMessageBody } from '../ItemMessagesListMessageBody';
import { ItemMessagesListMessageIdButtons } from '../ItemMessagesListMessageIdButtons';
import { ItemMessagesListMessageImages } from '../ItemMessagesListMessageImages';
import { ItemMessagesListMessageFiles } from '../ItemMessagesListMessageFiles';
import { ItemMessagesListMessageColors } from '../ItemMessagesListMessageColors';
import { ItemMessagesListMessageIcons } from '../ItemMessagesListMessageIcons';
import { ItemMessagesListMessageMenu } from '../ItemMessagesListMessageMenu';
import { ItemMessagesListMessageForwardedMessage } from '../ItemMessagesListMessageForwardedMessage';
import { ItemMessagesListMessageSendEmailNotificationButton } from '../ItemMessagesListMessageSendEmailNotificationButton';

import { UserAvatarLink } from '../../../../../../common/helpers/UserAvatarLink';
import { UserLink } from '../../../../../../common/helpers/UserLink';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { TooltipSingletonSourceWrapper } from '../../../../../../../helpers/tooltips/TooltipSingletonSourceWrapper';

import { MessagesPermissions } from '../../../../../messagesConstants';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

interface ItemMessagesListDefaultMessageProps {
  message: ItemMessagesListDefaultMessageItem;
  messagesCacheKey: FetchMessagesCacheKey;
  prevSameAuthor: boolean;
  nextSameAuthor: boolean;
  forwardMessageProjectUuid: MessageProjectUUID;
  forwardMessageTaskUuid?: MessageTaskUUID;
  togglePreventModalClose?: TogglePreventModalCloseAction;
}

function ItemMessagesListDefaultMessage({
  message,
  messagesCacheKey,
  prevSameAuthor,
  nextSameAuthor,
  forwardMessageProjectUuid,
  forwardMessageTaskUuid,
  togglePreventModalClose
}: ItemMessagesListDefaultMessageProps) {
  const withoutBody = isEmpty(message.body);

  return (
    <div
      id={`message-${message.uuid}`}
      className={cl('mr-7', nextSameAuthor ? 'mb-0.5' : 'mb-5')}
    >
      <div className="flex">
        <div className="h-7 w-7 sm:h-9 sm:w-9 mr-2 flex-shrink-0">
          {prevSameAuthor ? null : (
            <UserAvatarLink
              className="max-w-none mt-5 w-full h-full rounded-full bg-gray-200 dark:bg-gray-800"
              user={message.user}
            />
          )}
        </div>
        <div className="flex flex-col flex-1">
          {prevSameAuthor ? null : (
            <div className="text-xs mb-0.5 ml-2 text-gray-600 dark:text-gray-400">
              <CheckPermissions action={MessagesPermissions.READ_MESSAGE_ID}>
                <span>{message.id}</span>
              </CheckPermissions>
              <ItemMessagesListMessageIdButtons message={message} />{' '}
              <UserLink user={message.user} text={message.user?.fullName} />
            </div>
          )}
          <div className="w-full">
            <CheckPermissions
              action={MessagesPermissions.READ_MESSAGE_FORWARD_MESSAGE}
            >
              {isEmpty(message.forwardedMessage) ? null : (
                <ItemMessagesListMessageForwardedMessage message={message} />
              )}
            </CheckPermissions>
            {withoutBody ? null : (
              <div className="group flex justify-start">
                <div>
                  <ItemMessagesListMessageBody
                    message={message}
                    messagesCacheKey={messagesCacheKey}
                    prevSameAuthor={prevSameAuthor}
                    nextSameAuthor={nextSameAuthor}
                  />
                </div>

                <ItemMessagesListMessageMenu
                  forwardMessageProjectUuid={forwardMessageProjectUuid}
                  forwardMessageTaskUuid={forwardMessageTaskUuid}
                  message={message}
                  messagesCacheKey={messagesCacheKey}
                />
              </div>
            )}
            <TooltipSingletonSourceWrapper
              placement={TooltipPlacement.TOP}
              withArrow
            >
              <ItemMessagesListMessageImages
                message={message}
                messagesCacheKey={messagesCacheKey}
                forwardMessageProjectUuid={forwardMessageProjectUuid}
                forwardMessageTaskUuid={forwardMessageTaskUuid}
                togglePreventModalClose={togglePreventModalClose}
              />
              <ItemMessagesListMessageFiles
                message={message}
                messagesCacheKey={messagesCacheKey}
                forwardMessageProjectUuid={forwardMessageProjectUuid}
                forwardMessageTaskUuid={forwardMessageTaskUuid}
              />
            </TooltipSingletonSourceWrapper>

            <ItemMessagesListMessageColors
              message={message}
              messagesCacheKey={messagesCacheKey}
              forwardMessageProjectUuid={forwardMessageProjectUuid}
              forwardMessageTaskUuid={forwardMessageTaskUuid}
            />
            <ItemMessagesListMessageIcons
              message={message}
              messagesCacheKey={messagesCacheKey}
            />
            {withoutBody ? (
              <ItemMessagesListMessageSendEmailNotificationButton
                message={message}
                messagesCacheKey={messagesCacheKey}
                className="mt-1 flex items-center"
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemMessagesListDefaultMessage;
