import React from 'react';

import { TogglePreventModalCloseAction } from '../../../../../../../helpers/modals/modalsTypes';
import { ClassName } from '../../../../../../../types';
import { FetchMessagesCacheKey } from '../../../../../messagesTypes';
import {
  ItemMessagesListMessageImageFileAttachmentAttachmentItem,
  ItemMessagesListMessageImageFileAttachmentMessage
} from './ItemMessagesListMessageImageFileAttachment.types';

import {
  ImageHelper,
  FileAttachmentItemImageVersions
} from '../../../../../../../helpers/ImageHelper';
import { LinkHelper } from '../../../../../../../helpers/links/LinkHelper';
import { Translate } from '../../../../../../../helpers/Translate';

import { attachmentsKeys } from '../../../../../../../locales/keys';

import { ItemMessagesListMessageImageFileAttachmentButtons } from './components/ItemMessagesListMessageImageFileAttachmentButtons';
import { ItemMessagesListMessageImageVisibleForClient } from '../ItemMessagesListMessageImageVisibleForClient';
import { ItemMessagesListMessageImageHandleOpenLightbox } from '../ItemMessagesListMessageImage';

interface ItemMessagesListMessageImageFileAttachmentProps {
  message: ItemMessagesListMessageImageFileAttachmentMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  linkClassName: ClassName;
  imageClassName: ClassName;
  attachment: ItemMessagesListMessageImageFileAttachmentAttachmentItem;
  isVisibleForClient: boolean;
  handleOpenLightbox: ItemMessagesListMessageImageHandleOpenLightbox;
  togglePreventModalClose?: TogglePreventModalCloseAction;
}

function ItemMessagesListMessageImageFileAttachment({
  message,
  messagesCacheKey,
  linkClassName,
  imageClassName,
  attachment,
  isVisibleForClient,
  handleOpenLightbox,
  togglePreventModalClose
}: ItemMessagesListMessageImageFileAttachmentProps) {
  return (
    <div className="group/item relative rounded-md border border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-850 hover:bg-gray-200 dark:hover:bg-gray-800">
      <LinkHelper
        className={linkClassName}
        href={attachment.file}
        target="_blank"
        onClick={handleOpenLightbox}
      >
        <ImageHelper
          className={imageClassName}
          src={attachment.file}
          alt={attachment.name}
          version={FileAttachmentItemImageVersions.BigThumb538x435}
          tooltipAlt
          tooltipAltInteractive
          tooltipAltAppendToBody
        />
      </LinkHelper>
      <div className="absolute inset-0 flex items-end justify-start p-2 pointer-events-none space-x-1">
        {isVisibleForClient && <ItemMessagesListMessageImageVisibleForClient />}
        {attachment.drawing ? (
          <span className="px-1.5 py-0.5 text-white rounded shadow text-xs bg-gray-600">
            <Translate id={attachmentsKeys.drawings} />
          </span>
        ) : null}
        {attachment.reference ? (
          <span className="px-1.5 py-0.5 text-white rounded shadow text-xs bg-gray-600">
            <Translate id={attachmentsKeys.references} />
          </span>
        ) : null}
        {attachment.texture ? (
          <span className="px-1.5 py-0.5 text-white rounded shadow text-xs bg-gray-600">
            <Translate id={attachmentsKeys.textures} />
          </span>
        ) : null}
      </div>
      <ItemMessagesListMessageImageFileAttachmentButtons
        message={message}
        messagesCacheKey={messagesCacheKey}
        attachment={attachment}
        togglePreventModalClose={togglePreventModalClose}
      />
    </div>
  );
}

export default ItemMessagesListMessageImageFileAttachment;
