import { ItemMessagesListInvoiceActionMessageItem } from './ItemMessagesListInvoiceActionMessage.types';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { FetchMessagesCacheKey } from '../../../../../messagesTypes';

import { ItemMessagesListMessageSendEmailNotificationButton } from '../ItemMessagesListMessageSendEmailNotificationButton';

import { Icon } from '../../../../../../../helpers/Icon';
import { MoneyHelper } from '../../../../../../../helpers/MoneyHelper';
import { PureLinkHelper } from '../../../../../../../helpers/links/PureLinkHelper';
import { Translate } from '../../../../../../../helpers/Translate';

import { InvoicePath } from '../../../../../../invoices/InvoicePath';

import { invoicesKeys } from '../../../../../../../locales/keys';

interface ItemMessagesListInvoiceActionMessageProps {
  message: ItemMessagesListInvoiceActionMessageItem;
  messagesCacheKey: FetchMessagesCacheKey;
}
function ItemMessagesListInvoiceActionMessage({
  message,
  messagesCacheKey
}: ItemMessagesListInvoiceActionMessageProps) {
  const invoiceId = message.invoice?.clientId || message.invoiceId;

  return (
    <div className="mb-10 p-2">
      <div className="bg-gray-100 dark:bg-gray-850 border dark:border-gray-700 rounded-lg p-4">
        <div className="flex gap-4">
          <div>
            <Icon
              icon={IconsEnum.CASH_OUTLINE}
              className="h-10 w-10"
              strokeWidth="1"
            />
          </div>

          <div className="flex-1">
            <div className="font-semibold mb-1">
              <Translate id={invoicesKeys.singular} />
              &nbsp;
              {`#${invoiceId}`}
            </div>

            <div className="text-xs">
              The invoice has been paid for{' '}
              <span className="font-bold">
                <MoneyHelper value={1450} />
              </span>
              .
            </div>

            <div className="text-xs">
              Final payment of 1 450.00 USD is expected after current
              smart-contract is done.
            </div>

            <div className="flex gap-3 mt-1">
              <PureLinkHelper
                className="text-xs dark:text-gray-300 text-gray-700 underline"
                href={InvoicePath.paymentsInvoice(message.invoice?.nanoId)}
                i18nText={invoicesKeys.view}
              />

              <ItemMessagesListMessageSendEmailNotificationButton
                message={message}
                messagesCacheKey={messagesCacheKey}
                className="flex items-center"
              />
            </div>
          </div>

          <div className="px-4 py-1.5 rounded-full text-green-800 bg-green-200 self-start">
            Paid
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemMessagesListInvoiceActionMessage;
