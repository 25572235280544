import React, { useCallback, useState } from 'react';

import { ItemMessagesListInvoiceMessageDetailedView } from './components/ItemMessagesListInvoiceMessageDetailedView';
import { ItemMessagesListInvoiceMessageShortView } from './components/ItemMessagesListInvoiceMessageShortView';
import { ItemMessagesListMessageSendEmailNotificationButton } from '../ItemMessagesListMessageSendEmailNotificationButton';

import { ItemMessagesListInvoiceMessageItem } from './ItemMessagesListInvoiceMessage.types';
import { FetchMessagesCacheKey } from '../../../../../messagesTypes';
import { InvoiceStatuses } from '../../../../../../invoices/invoicesTypes';

interface ItemMessagesListInvoiceMessageProps {
  message: ItemMessagesListInvoiceMessageItem;
  messagesCacheKey: FetchMessagesCacheKey;
}
function ItemMessagesListInvoiceMessage({
  message,
  messagesCacheKey
}: ItemMessagesListInvoiceMessageProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleIsOpen = useCallback<() => void>(
    () => setIsOpen((prevState) => !prevState),
    []
  );

  const invoice = message?.bitInvoice;

  const primaryInvoiceStatus = invoice?.clientStatus || invoice?.status;

  const secondaryInvoiceStatus =
    invoice?.secondaryInvoice?.clientStatus ||
    invoice?.secondaryInvoice?.status;

  const invoiceStatus =
    secondaryInvoiceStatus === InvoiceStatuses.PAID
      ? secondaryInvoiceStatus
      : primaryInvoiceStatus;

  const prepayment = invoice?.prepayment || 0;

  const prepaymentAmount = invoice?.prepaymentAmount || 0;

  const payments =
    (invoice?.status === InvoiceStatuses.PAID && invoice?.amount) +
    (invoice?.secondaryInvoice?.status === InvoiceStatuses.PAID &&
      invoice?.secondaryInvoice?.amount);

  return (
    <div className="my-10 p-2">
      <div className="flex flex-col flex-1">
        <div className="w-full">
          <div className="border dark:border-gray-800">
            <div className="bg-white dark:bg-gray-900">
              {isOpen ? (
                <ItemMessagesListInvoiceMessageDetailedView
                  isOpen={isOpen}
                  message={message}
                  messagesCacheKey={messagesCacheKey}
                  toggleIsOpen={toggleIsOpen}
                  invoiceStatus={invoiceStatus}
                  prepayment={prepayment}
                  prepaymentAmount={prepaymentAmount}
                  payments={payments}
                />
              ) : (
                <ItemMessagesListInvoiceMessageShortView
                  isOpen={isOpen}
                  message={message}
                  messagesCacheKey={messagesCacheKey}
                  toggleIsOpen={toggleIsOpen}
                  invoiceStatus={invoiceStatus}
                  prepayment={prepayment}
                  prepaymentAmount={prepaymentAmount}
                  payments={payments}
                />
              )}
            </div>
          </div>

          <ItemMessagesListMessageSendEmailNotificationButton
            message={message}
            messagesCacheKey={messagesCacheKey}
            className="flex items-center justify-center mt-1"
          />
        </div>
      </div>
    </div>
  );
}
export default ItemMessagesListInvoiceMessage;
