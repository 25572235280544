import { ItemMessagesListResultsMessageResultFileFileAttachment } from '../../ItemMessagesListResultsMessageResultFile.types';
import { TaskNanoID } from '../../../../../../../../../../tasks/tasksTypes';
import { IconsEnum } from '../../../../../../../../../../../assets/icons/types';

import { useCurrentUser } from '../../../../../../../../../../../auth/hooks/useAuth';

import { SubmitSourceFilesToTaskModalButton } from '../../../../../../../../../../tasks/components/modalButtons/SubmitSourceFilesToTaskModalButton';

import { DropzoneHelperFile } from '../../../../../../../../../../../helpers/dropzone/DropzoneHelper';
import { TooltipDropdownHelper } from '../../../../../../../../../../../helpers/dropdowns/TooltipDropdownHelper';
import { PopoverPlacement } from '../../../../../../../../../../../helpers/Popover/popoverConstants';

import {
  tasksKeys,
  words
} from '../../../../../../../../../../../locales/keys';
import { MessagesPermissions } from '../../../../../../../../../messagesConstants';

interface ItemMessagesListResultsMessageResultFileDropdownProps {
  fileAttachment: ItemMessagesListResultsMessageResultFileFileAttachment;
  taskNanoId: TaskNanoID;
}

function ItemMessagesListResultsMessageResultFileDropdown({
  fileAttachment,
  taskNanoId
}: ItemMessagesListResultsMessageResultFileDropdownProps) {
  const currentUser = useCurrentUser();

  const withSubmitSourceFilesButton = currentUser.hasPermissions(
    MessagesPermissions.READ_MESSAGE_RESULT_SUBMIT_SOURCE_FILES_BUTTON
  );

  if (!withSubmitSourceFilesButton) {
    return null;
  }

  return (
    <TooltipDropdownHelper
      className="relative"
      buttonClassName="py-1 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
      icon={IconsEnum.DOTS_HORIZONTAL_OUTLINE}
      iconClassName="h-6 w-6"
      dropdownPlacement={PopoverPlacement.BOTTOM_START}
      tooltipI18nText={words.more}
      tooltipSingleton
    >
      {withSubmitSourceFilesButton && (
        <SubmitSourceFilesToTaskModalButton
          taskNanoId={taskNanoId}
          className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
          i18nText={tasksKeys.submitAsSourceFile}
          initialFile={fileAttachment}
        />
      )}
    </TooltipDropdownHelper>
  );
}

export default ItemMessagesListResultsMessageResultFileDropdown;
