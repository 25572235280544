import { useMemo } from 'react';
import cl from 'classnames';
import compact from 'lodash/compact';
import groupBy from 'lodash/groupBy';
import includes from 'lodash/includes';
import map from 'lodash/map';

import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';
import {
  InvoicePrepayment,
  InvoicePrepaymentAmount,
  InvoiceStatus,
  InvoiceStatuses
} from '../../../../../../../../invoices/invoicesTypes';

import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import { ItemMessagesListInvoiceMessageItem } from '../../ItemMessagesListInvoiceMessage.types';
import { InvoiceItemsType } from '../../../../../../../../invoices/components/InvoiceItemsTableBlock/hooks/useInvoiceItemsTableBlock';
import {
  ItemMessagesListInvoiceMessageHeaderButtons,
  ItemMessagesListInvoiceMessageHeaderButtonsMessage
} from '../ItemMessagesListInvoiceMessageHeaderButtons';

import { PayInvoiceModalButton } from '../../../../../../../../invoices/components/modalButton/PayInvoiceModalButton';
import { InvoiceItemsTable } from '../../../../../../../../invoices/components/InvoiceItemsTable';
import { ItemMessagesListInvoiceMessageHeader } from '../ItemMessagesListInvoiceMessageHeader';

import { AlertMessage } from '../../../../../../../../../helpers/AlertMessage';
import { AvBillingInfoHelper } from '../../../../../../../../avBillingInfos/helpers/AvBillingInfoHelper';
import { BillingInfoHelper } from '../../../../../../../../billingInfos/helpers/BillingInfoHelper';
import { CheckPermissions } from '../../../../../../../../../helpers/CheckPermissions';
import { Icon } from '../../../../../../../../../helpers/Icon';
import { LoadingSkeleton } from '../../../../../../../../../helpers/LoadingSkeleton';
import { TeamUpsalersHelper } from '../../../../../../../../teamsUpsalers/helpers/TeamUpsalersHelper';
import { TextField } from '../../../../../../../../../helpers/FormFields/TextField';
import { AvBillinIngoPaymentMethodHelper } from '../../../../../../../../avBillingInfos/helpers/AvBillinIngoPaymentMethodHelper';

import {
  billingInfosKeys,
  invoicesKeys,
  words
} from '../../../../../../../../../locales/keys';

import { APP_CRM_NAME } from '../../../../../../../../../config';

import { bgCrossedInvoiceStatuses } from '../../../../../../../../invoices/invoicesConstants';
import { MessagesPermissions } from '../../../../../../../messagesConstants';

const appIcon =
  APP_CRM_NAME === 'SmashingCGI' ? IconsEnum.SMASHING_CGI : IconsEnum.AV;

interface ItemMessagesListInvoiceMessageDetailedViewProps {
  isOpen: boolean;
  message: ItemMessagesListInvoiceMessageItem &
    ItemMessagesListInvoiceMessageHeaderButtonsMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  toggleIsOpen: () => void;
  invoiceStatus: InvoiceStatus;
  prepayment: InvoicePrepayment;
  prepaymentAmount: InvoicePrepaymentAmount;
  payments: number;
}

function ItemMessagesListInvoiceMessageDetailedView({
  isOpen,
  message,
  messagesCacheKey,
  toggleIsOpen,
  invoiceStatus,
  prepayment,
  prepaymentAmount,
  payments
}: ItemMessagesListInvoiceMessageDetailedViewProps) {
  const invoice = message?.bitInvoice;
  const invoiceError = message?.bitInvoiceErrorMessage;
  const invoiceFetched = message?.bitInvoiceFetched;

  const invoiceItems = useMemo<InvoiceItemsType>(() => {
    if (!invoice) {
      return [];
    }

    const items = compact(map(invoice.childInvoices, 'item'));

    const groupedItems = groupBy(
      items,
      (item) => `${item.itemCategoryName}-${item.itemTypeName}-${item.price}`
    );

    const mappedItems = map(groupedItems, (items) => ({
      itemTypeName: items[0]?.itemTypeName,
      price: items[0]?.price,
      quantity: items.length,
      groupBy: items[0]?.itemCategoryName
    }));

    return map(
      groupBy(mappedItems, (item) => item.groupBy),
      (items, groupBy) => {
        return {
          header: (
            <td
              className="font-bold text-xs py-1 bg-gray-50 dark:bg-gray-850"
              colSpan={4}
            >
              {groupBy}
            </td>
          ),
          items
        };
      }
    );
  }, [invoice]);

  return (
    <>
      <div
        className={cl('relative bg-gray-50 dark:bg-gray-850', {
          'bg-crossed': includes(
            bgCrossedInvoiceStatuses,
            message.invoice?.status
          )
        })}
      >
        <ItemMessagesListInvoiceMessageHeaderButtons
          isOpen={isOpen}
          message={message}
          messagesCacheKey={messagesCacheKey}
          toggleIsOpen={toggleIsOpen}
        />

        <div className="max-w-3xl mx-auto flex gap-6 px-6 py-8">
          <div className="flex-1">
            <Icon
              icon={appIcon}
              className="object-cover w-20 h-20 rounded text-white bg-gradient-to-tr from-blue-600 to-blue-400 p-5"
            />
          </div>

          <ItemMessagesListInvoiceMessageHeader message={message} />
        </div>
      </div>

      <LoadingSkeleton loaded={invoiceFetched} count={5}>
        {invoice ? (
          <>
            {/* Billing info */}
            <div className="max-w-3xl mx-auto px-6 pt-8 pb-4">
              <div className="flex gap-6 mb-4">
                {/* Bill from */}
                <CheckPermissions
                  action={
                    MessagesPermissions.READ_MESSAGE_INVOICE_AV_BILLING_INFO
                  }
                >
                  <div className="flex-1">
                    <div className="text-xs">
                      <AvBillingInfoHelper
                        avBillingInfo={invoice.avInvoiceBillingInfo}
                        i18nLabel={billingInfosKeys.billFromColon}
                        i18nWarning={
                          billingInfosKeys.receiverBillingInfoWillFillAutomaticallyAfterPayerBillingInfoIsProvided
                        }
                      />
                    </div>
                  </div>
                </CheckPermissions>

                {/* Bill to */}
                <CheckPermissions
                  action={MessagesPermissions.READ_MESSAGE_INVOICE_BILLING_INFO}
                >
                  <div className="flex-1">
                    <div className="text-xs">
                      <BillingInfoHelper
                        billingInfo={invoice.invoiceBillingInfo}
                        i18nLabel={billingInfosKeys.billToColon}
                        i18nWarning={
                          billingInfosKeys.provideYourBillingInfoInOrderToCreateInvoiceForTheProject
                        }
                      />
                    </div>
                  </div>
                </CheckPermissions>
              </div>

              {/* Client manager */}
              <CheckPermissions
                action={MessagesPermissions.READ_MESSAGE_INVOICE_TEAM_UPSALERS}
              >
                <TeamUpsalersHelper
                  teamUuid={invoice.generalLedger?.company?.uuid}
                  i18nLabel={words.clientManager}
                />
              </CheckPermissions>

              {/* Payment method */}
              <CheckPermissions
                action={MessagesPermissions.READ_MESSAGE_INVOICE_PAYMENT_METHOD}
              >
                <AvBillinIngoPaymentMethodHelper
                  paymentMethod={invoice?.avInvoiceBillingInfo?.paymentMethod}
                />
              </CheckPermissions>
            </div>

            <div className="max-w-3xl mx-auto px-2 sm:px-6 pt-4 pb-8">
              {/* Items */}
              <CheckPermissions
                action={MessagesPermissions.READ_MESSAGE_INVOICE_ITEMS}
              >
                <InvoiceItemsTable
                  amountDue={
                    invoiceStatus === InvoiceStatuses.PREPAID
                      ? invoice?.secondaryInvoice?.amount
                      : invoice?.amount
                  }
                  invoiceTotal={message.invoice?.invoiceTotal}
                  items={invoiceItems}
                  prepayment={prepayment}
                  prepaymentAmount={prepaymentAmount}
                  status={invoiceStatus}
                  payments={payments}
                />
              </CheckPermissions>

              {/* Notes */}
              {invoice.notes && (
                <CheckPermissions
                  action={MessagesPermissions.READ_MESSAGE_INVOICE_NOTES}
                >
                  <TextField
                    i18nLabel={invoicesKeys.notesColon}
                    wrapperClassName="mt-4"
                    value={invoice.notes}
                  />
                </CheckPermissions>
              )}

              {/* Terms */}
              {invoice.terms && (
                <CheckPermissions
                  action={MessagesPermissions.READ_MESSAGE_INVOICE_TERMS}
                >
                  <TextField
                    i18nLabel={invoicesKeys.termsColon}
                    value={invoice.terms}
                  />
                </CheckPermissions>
              )}

              {/* Pay */}
              {invoice.showPayCardButton && invoice.nanoId && (
                <CheckPermissions
                  action={MessagesPermissions.READ_MESSAGE_INVOICE_PAY_BUTTON}
                >
                  <div className="mt-4 flex items-center justify-center gap-0.5">
                    <PayInvoiceModalButton
                      invoiceNanoId={invoice.nanoId}
                      className="py-1.5 pl-3 pr-3 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-white bg-blue-500 hover:bg-blue-600 shadow-sm hover:shadow-md"
                      i18nText={words.pay}
                      disabled={invoice.disabledPayCardButton}
                    />
                  </div>
                </CheckPermissions>
              )}
            </div>
          </>
        ) : null}
      </LoadingSkeleton>

      <AlertMessage message={invoiceError} />
    </>
  );
}

export default ItemMessagesListInvoiceMessageDetailedView;
