import React from 'react';

import { TaskStatuses } from '../../../../../../../../tasks/tasksTypes';
import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import { PositionStyleEnum } from '../../../../../../../../../types';
import { ItemMessagesListResultsMessageItem } from '../../ItemMessagesListResultsMessage.types';
import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';

import { useCurrentUser } from '../../../../../../../../../auth/hooks/useAuth';

import { ItemMessagesListResultsMessageMenuDropdownDownloadButton } from './components/ItemMessagesListResultsMessageMenuDropdownDownloadButton';
import { ItemMessagesListResultsMessageMenuDropdownSendResultsToClientButton } from './components/ItemMessagesListResultsMessageMenuDropdownSendResultsToClientButton';
import { ItemMessagesListResultsMessageMenuDropdownVFCButton } from './components/ItemMessagesListResultsMessageMenuDropdownVFCButton';
import { ItemMessagesListResultsMessageMenuDropdownIsResultButton } from './components/ItemMessagesListResultsMessageMenuDropdownIsResultButton';

import { TooltipDropdownHelper } from '../../../../../../../../../helpers/dropdowns/TooltipDropdownHelper';
import { PopoverPlacement } from '../../../../../../../../../helpers/Popover/popoverConstants';

import { words } from '../../../../../../../../../locales/keys';
import { MessagesPermissions } from '../../../../../../../messagesConstants';

interface ItemMessagesListResultsMessageMenuDropdownProps {
  message: ItemMessagesListResultsMessageItem;
  messagesCacheKey: FetchMessagesCacheKey;
}

function ItemMessagesListResultsMessageMenuDropdown({
  message,
  messagesCacheKey
}: ItemMessagesListResultsMessageMenuDropdownProps) {
  const currentUser = useCurrentUser();

  const withSendViaEmailButton =
    message.task.status === TaskStatuses.READY_FOR_ACCEPTANCE &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_RESULT_SEND_TO_CLIENT_BUTTON
    );

  const withDownloadButton = currentUser.hasPermissions(
    MessagesPermissions.READ_MESSAGE_RESULT_DOWNLOAD_BUTTON
  );

  const withVFCButton = currentUser.hasPermissions(
    MessagesPermissions.READ_MESSAGE_RESULT_VISIBLE_FOR_CLIENT_BUTTON
  );

  const withIsResultButton = currentUser.hasPermissions(
    MessagesPermissions.READ_MESSAGE_RESULT_IS_RESULT_BUTTON
  );

  if (
    !withSendViaEmailButton &&
    !withDownloadButton &&
    !withVFCButton &&
    !withIsResultButton
  ) {
    return null;
  }

  return (
    <div className="absolute top-2 right-2">
      <TooltipDropdownHelper
        buttonClassName="py-1.5 pl-1.5 pr-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
        icon={IconsEnum.DOTS_HORIZONTAL_OUTLINE}
        popoverPositionStyle={PositionStyleEnum.fixed}
        tooltipI18nText={words.more}
        dropdownPlacement={PopoverPlacement.BOTTOM_END}
        tooltipSingleton
      >
        {withDownloadButton ? (
          <ItemMessagesListResultsMessageMenuDropdownDownloadButton
            message={message}
          />
        ) : null}

        {withSendViaEmailButton ? (
          <ItemMessagesListResultsMessageMenuDropdownSendResultsToClientButton
            message={message}
          />
        ) : null}

        {withVFCButton ? (
          <ItemMessagesListResultsMessageMenuDropdownVFCButton
            messagesCacheKey={messagesCacheKey}
            message={message}
          />
        ) : null}

        {withIsResultButton ? (
          <ItemMessagesListResultsMessageMenuDropdownIsResultButton
            messagesCacheKey={messagesCacheKey}
            message={message}
          />
        ) : null}
      </TooltipDropdownHelper>
    </div>
  );
}

export default ItemMessagesListResultsMessageMenuDropdown;
