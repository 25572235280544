import { ClassName } from '../../../../../../../types';
import { FetchMessagesCacheKey } from '../../../../../messagesTypes';

import { MessagesListMessageImageLifestyleButtons } from '../../../MessagesListMessage/components/MessagesListMessageImageLifestyle/components/MessagesListMessageImageLifestyleButtons';
import { ItemMessagesListMessageImageVisibleForClient } from '../ItemMessagesListMessageImageVisibleForClient';
import {
  ItemMessagesListMessageImageSelectedLifestyleAttachment,
  ItemMessagesListMessageImageHandleOpenLightbox
} from '../ItemMessagesListMessageImage';

import {
  ImageHelper,
  FileAttachmentItemImageVersions
} from '../../../../../../../helpers/ImageHelper';
import { LinkHelper } from '../../../../../../../helpers/links/LinkHelper';
import { Translate } from '../../../../../../../helpers/Translate';

import { lifestylesKeys } from '../../../../../../../locales/keys';

interface ItemMessagesListMessageImageLifestyleProps {
  messagesCacheKey: FetchMessagesCacheKey;
  linkClassName: ClassName;
  imageClassName: ClassName;
  attachment: ItemMessagesListMessageImageSelectedLifestyleAttachment;
  isVisibleForClient: boolean;
  handleOpenLightbox: ItemMessagesListMessageImageHandleOpenLightbox;
}

function ItemMessagesListMessageImageLifestyle({
  messagesCacheKey,
  linkClassName,
  imageClassName,
  attachment,
  isVisibleForClient,
  handleOpenLightbox
}: ItemMessagesListMessageImageLifestyleProps) {
  return (
    <div className="group/item relative rounded-md border border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-850 hover:bg-gray-200 dark:hover:bg-gray-800">
      <LinkHelper
        className={linkClassName}
        href={attachment.lifestyle.image.file}
        target="_blank"
        onClick={handleOpenLightbox}
      >
        <ImageHelper
          className={imageClassName}
          src={attachment.lifestyle.image.file}
          alt={attachment.lifestyle.name}
          version={FileAttachmentItemImageVersions.BigThumb538x435}
          tooltipAlt
          tooltipAltInteractive
          tooltipAltAppendToBody
        />
      </LinkHelper>
      <div className="absolute inset-0 flex items-end justify-start p-2 pointer-events-none space-x-1">
        {isVisibleForClient && <ItemMessagesListMessageImageVisibleForClient />}
        <span className="px-1.5 py-0.5 text-white rounded shadow text-xs bg-pink-600">
          <Translate id={lifestylesKeys.plural} />
        </span>
      </div>
      <MessagesListMessageImageLifestyleButtons
        attachment={attachment}
        messagesCacheKey={messagesCacheKey}
      />
    </div>
  );
}

export default ItemMessagesListMessageImageLifestyle;
