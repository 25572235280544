import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import cl from 'classnames';

import { ItemMessagesListMessageFilesMessage } from './ItemMessagesListMessageFiles.types';
import {
  FetchMessagesCacheKey,
  MessageProjectUUID,
  MessageTaskUUID
} from '../../../../../messagesTypes';
import { TooltipMessageDate } from '../../../../tooltips/TooltipMessageDate';

import { ItemMessagesListMessageFile } from '../ItemMessagesListMessageFile';
import { ItemMessagesListMessageMenu } from '../ItemMessagesListMessageMenu';

import { Files } from '../../../../../../../utils/Files';

interface ItemMessagesListMessageFilesProps {
  message: ItemMessagesListMessageFilesMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  reverse?: boolean;
  forwardMessageProjectUuid: MessageProjectUUID;
  forwardMessageTaskUuid?: MessageTaskUUID;
}

function ItemMessagesListMessageFiles({
  message,
  messagesCacheKey,
  reverse,
  forwardMessageProjectUuid,
  forwardMessageTaskUuid
}: ItemMessagesListMessageFilesProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLDivElement | null>(null);

  const fileAttachments = message.fileAttachments?.filter(
    (fileAttachment) => !Files.isImage(fileAttachment.file)
  );

  const isVisibleForClient = !message.body && message.visibleForClient;

  if (isEmpty(fileAttachments)) {
    return null;
  }

  return (
    <div
      className={cl('group flex', {
        'flex-row-reverse': reverse
      })}
      ref={setReferenceTooltipElement}
    >
      <div className="p-0.5 -m-0.5 overflow-hidden rounded-2xl max-w-xl">
        <div className="flex flex-wrap gap-1">
          {fileAttachments.map((fileAttachment) => (
            <ItemMessagesListMessageFile
              key={fileAttachment.uuid}
              messagesCacheKey={messagesCacheKey}
              fileAttachment={fileAttachment}
              message={message}
              isVisibleForClient={isVisibleForClient}
              tooltipSingleton
            />
          ))}
        </div>
      </div>
      {!message.body && (
        <ItemMessagesListMessageMenu
          message={message}
          messagesCacheKey={messagesCacheKey}
          forwardMessageProjectUuid={forwardMessageProjectUuid}
          forwardMessageTaskUuid={forwardMessageTaskUuid}
        />
      )}
      <TooltipMessageDate
        referenceElement={referenceTooltipElement}
        date={message.createdAt}
      />
    </div>
  );
}

export default ItemMessagesListMessageFiles;
