import React, { Fragment, useState } from 'react';
import cl from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { FetchMessagesCacheKey } from '../../../../../messagesTypes';
import { ItemMessagesListMessageBodyMessage } from './ItemMessagesListMessageBody.types';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { ItemMessagesListMessageSendEmailNotificationButton } from '../ItemMessagesListMessageSendEmailNotificationButton';

import { TooltipMessageDate } from '../../../../tooltips/TooltipMessageDate';

import { MarkdownHelper } from '../../../../../../../helpers/MarkdownHelper';

import { MessagesPermissions } from '../../../../../messagesConstants';

interface ItemMessagesListMessageBodyProps {
  message: ItemMessagesListMessageBodyMessage;
  messagesCacheKey?: FetchMessagesCacheKey;
  reverse?: boolean;
  prevSameAuthor: boolean;
  nextSameAuthor: boolean;
}

function ItemMessagesListMessageBody({
  message,
  messagesCacheKey,
  reverse,
  prevSameAuthor,
  nextSameAuthor
}: ItemMessagesListMessageBodyProps) {
  const currentUser = useCurrentUser();
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLDivElement | null>(null);

  return (
    <Fragment>
      <div
        ref={setReferenceTooltipElement}
        className={cl(
          'inline-flex px-3 py-2 rounded-xl text-sm sm:text-md leading-snug max-w-xl relative',
          {
            'bg-gray-200 dark:bg-gray-700': !message.visibleForClient,
            'bg-blue-100 dark:bg-gray-300 dark:text-black':
              message.visibleForClient && !message.user?.client,
            'bg-blue-100 border-blue-500 border-l-8 dark:text-black':
              message.visibleForClient && message.user?.client,
            'ring-1 ring-white dark:ring-gray-900':
              currentUser.hasPermissions(
                MessagesPermissions.READ_MESSAGE_FORWARD_MESSAGE
              ) && !isEmpty(message.forwardedMessage),
            'rounded-tl': prevSameAuthor && !reverse,
            'rounded-bl': nextSameAuthor && !reverse,
            'rounded-tr': prevSameAuthor && reverse,
            'rounded-br': nextSameAuthor && reverse
          }
        )}
      >
        <div>
          <MarkdownHelper
            source={message.body}
            className={cl(
              'break-wrap markdown markdown-sm sm:markdown-md markdown-pre:whitespace-pre-wrap leading-snug',
              {
                'dark:markdown-invert': !message.user?.client
              }
            )}
            fromMessage
            linkTarget={
              currentUser.hasPermissions(
                MessagesPermissions.READ_MESSAGE_LINK_BLANK
              )
                ? '_blank'
                : undefined
            }
          />

          <ItemMessagesListMessageSendEmailNotificationButton
            message={message}
            messagesCacheKey={messagesCacheKey}
            className="flex items-center mt-1"
            messageSendEmailNotificationButtonClassName={
              message.visibleForClient
                ? 'text-xs text-gray-700 flex items-center dark:text-black'
                : null
            }
          />
        </div>
      </div>
      <TooltipMessageDate
        referenceElement={referenceTooltipElement}
        date={message.createdAt}
      />
    </Fragment>
  );
}

export default ItemMessagesListMessageBody;
