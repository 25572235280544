import React from 'react';
import size from 'lodash/size';
import cl from 'classnames';

import { ItemMessagesListMessageMenuMessage } from './ItemMessagesListMessageMenu.types';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import {
  FetchMessagesCacheKey,
  MessageProjectUUID,
  MessageTaskUUID
} from '../../../../../messagesTypes';

import { ItemMessagesListMessageMenuDropdown } from '../ItemMessagesListMessageMenuDropdown';
import { ItemMessagesListMessageLikesPopover } from '../ItemMessagesListMessageLikesPopover';
import { ItemMessagesListMessageDownloadFileAttachmentsButton } from '../ItemMessagesListMessageDownloadFileAttachmentsButton';
import { ForwardMessageModalButton } from '../../../../modalButtons/ForwardMessageModalButton';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';
import { TooltipSingletonSourceWrapper } from '../../../../../../../helpers/tooltips/TooltipSingletonSourceWrapper';

import { MessagesPermissions } from '../../../../../messagesConstants';

import { attachmentsKeys, words } from '../../../../../../../locales/keys';

interface ItemMessagesListMessageMenuProps {
  isLeft?: boolean;
  message: ItemMessagesListMessageMenuMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  forwardMessageProjectUuid: MessageProjectUUID;
  forwardMessageTaskUuid?: MessageTaskUUID;
}

function ItemMessagesListMessageMenu({
  isLeft,
  message,
  messagesCacheKey,
  forwardMessageProjectUuid,
  forwardMessageTaskUuid
}: ItemMessagesListMessageMenuProps) {
  return (
    <TooltipSingletonSourceWrapper placement={TooltipPlacement.TOP} withArrow>
      <div
        className={cl(
          'flex sm:opacity-0 group-hover:opacity-100 focus-within:opacity-100 self-stretch items-center space-x-0.5',
          {
            'order-first flex-row-reverse mr-2 space-x-reverse': isLeft,
            'ml-2': !isLeft
          }
        )}
      >
        <CheckPermissions
          action={MessagesPermissions.READ_MESSAGE_LIKES_BUTTON}
        >
          <ItemMessagesListMessageLikesPopover
            messageUuid={message.uuid}
            messagesCacheKey={messagesCacheKey}
            tooltipSingleton
          />
        </CheckPermissions>
        {!message.body && size(message.fileAttachments) > 0 ? (
          <CheckPermissions
            action={
              MessagesPermissions.READ_MESSAGE_DOWNLOAD_FILE_ATTACHMENTS_BUTTON
            }
          >
            <ItemMessagesListMessageDownloadFileAttachmentsButton
              uuid={message.uuid}
              tooltipI18nText={
                size(message.fileAttachments) > 1
                  ? words.downloadAll
                  : attachmentsKeys.download
              }
              tooltipSingleton
            />
          </CheckPermissions>
        ) : (
          <CheckPermissions
            action={MessagesPermissions.READ_MESSAGE_FORWARD_BUTTON}
          >
            <ForwardMessageModalButton
              message={message}
              messagesCacheKey={messagesCacheKey}
              projectUuid={forwardMessageProjectUuid}
              taskUuid={forwardMessageTaskUuid}
              icon={IconsEnum.FORWARD}
              tooltipI18nText={words.forward}
              tooltipPlacement={TooltipPlacement.TOP}
              tooltipSingleton
            />
          </CheckPermissions>
        )}
        <ItemMessagesListMessageMenuDropdown
          message={message}
          messagesCacheKey={messagesCacheKey}
          forwardMessageProjectUuid={forwardMessageProjectUuid}
          forwardMessageTaskUuid={forwardMessageTaskUuid}
          withPropagateButton
          tooltipSingleton
        />
      </div>
    </TooltipSingletonSourceWrapper>
  );
}

export default ItemMessagesListMessageMenu;
