import React, { useCallback, useMemo, useState } from 'react';
import cl from 'classnames';
import size from 'lodash/size';
import sortBy from 'lodash/sortBy';

import { TogglePreventModalCloseAction } from '../../../../../../../helpers/modals/modalsTypes';
import { FileAttachmentItemImageVersions } from '../../../../../../../helpers/ImageHelper';

import { useImageEditorSubmitWithItemNanoId } from '../../../../../../../helpers/ImageEditor/hooks/useImageEditorSubmitWithItemNanoId';
import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import {
  ItemMessagesListMessageImage,
  mapMessageAttachmentImage
} from '../ItemMessagesListMessageImage';
import {
  LightboxRenderImageTitle,
  LightboxWrapper,
  useLightboxWrapper,
  LightboxThumbnails
} from '../../../../../../../helpers/LightboxWrapper';
import { ModelLightboxTitle } from '../../../../../../common/helpers/ModelLightboxTitle';
import { ItemMessagesListMessageMenu } from '../ItemMessagesListMessageMenu';
import { TooltipMessageDate } from '../../../../tooltips/TooltipMessageDate';

import { Files } from '../../../../../../../utils/Files';

import { ItemMessagesListMessageImagesMessage } from './ItemMessagesListMessageImages.types';
import {
  FetchMessagesCacheKey,
  MessageProjectUUID,
  MessageTaskUUID
} from '../../../../../messagesTypes';

import { LightboxWrapperPermissions } from '../../../../../../../helpers/LightboxWrapper/lightboxWrapperConstants';
import { MessagesPermissions } from '../../../../../messagesConstants';

interface ItemMessagesListMessageImagesProps {
  message: ItemMessagesListMessageImagesMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  reverse?: boolean;
  forwardMessageProjectUuid: MessageProjectUUID;
  forwardMessageTaskUuid?: MessageTaskUUID;
  togglePreventModalClose?: TogglePreventModalCloseAction;
}

function ItemMessagesListMessageImages({
  message,
  messagesCacheKey,
  reverse,
  forwardMessageProjectUuid,
  forwardMessageTaskUuid,
  togglePreventModalClose
}: ItemMessagesListMessageImagesProps) {
  const currentUser = useCurrentUser();

  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLDivElement | null>(null);

  const fileAttachments = message.fileAttachments?.filter((fileAttachment) =>
    Files.isImage(fileAttachment.file)
  );
  const selectedProducts = message.selectedProducts?.map(
    (selectedProduct) => selectedProduct
  );
  const selectedLifestyles = message.selectedLifestyles?.map(
    (selectedLifestyle) => selectedLifestyle
  );
  const selectedMaterials = message.selectedMaterials?.map(
    (selectedMaterial) => selectedMaterial
  );
  const attachments = sortBy(
    [
      ...fileAttachments,
      ...selectedProducts,
      ...selectedLifestyles,
      ...selectedMaterials
    ],
    (attachment) => attachment.createdAt
  );

  const attachmentsInLightbox = useMemo(() => {
    return attachments.filter((attachment) => {
      const lightboxImage = mapMessageAttachmentImage(attachment);
      return lightboxImage && Files.isImage(lightboxImage?.file);
    });
  }, [attachments]);

  const lightboxItems = useMemo(() => {
    return attachmentsInLightbox.map(mapMessageAttachmentImage);
  }, [attachmentsInLightbox]);

  const lightboxThumbnails = useMemo<LightboxThumbnails>(() => {
    return attachmentsInLightbox.map((attachment) =>
      attachment.type === 'file_attachment'
        ? Files.urlFromFile(
            attachment.file,
            FileAttachmentItemImageVersions.FullScreenThumb1000x850
          )
        : null
    );
  }, [attachmentsInLightbox]);

  const {
    handleLightboxClose,
    handleLightboxNext,
    handleLightboxOpen,
    handleLightboxOpenOnSlide,
    handleLightboxPrev,
    index,
    imageItem,
    lightBoxOpened,
    mainSrc,
    prevSrc,
    nextSrc,
    mainSrcThumbnail,
    prevSrcThumbnail,
    nextSrcThumbnail
  } = useLightboxWrapper({
    items: lightboxItems,
    thumbnails: currentUser.hasPermissions(
      LightboxWrapperPermissions.READ_LIGHTBOX_PLACEHOLDER_THUMBNAILS
    )
      ? lightboxThumbnails
      : null,
    toggleBackdrop: togglePreventModalClose
  });

  const { handleImageEditorSubmitWithItemNanoId } =
    useImageEditorSubmitWithItemNanoId(
      message.project?.nanoId
        ? { projectNanoId: message.project.nanoId }
        : { taskNanoId: message.task?.nanoId }
    );

  const currentLightboxImageAttachment = attachmentsInLightbox[index];

  const attachmentsTotalCount = size(attachments);

  const isVisibleForClient = !message.body && message.visibleForClient;

  const renderImageTitle = useCallback<LightboxRenderImageTitle>(() => {
    if (!currentLightboxImageAttachment) {
      return null;
    }

    if (
      currentLightboxImageAttachment.type === 'selected_lifestyle' ||
      currentLightboxImageAttachment.type === 'selected_material' ||
      currentLightboxImageAttachment.type === 'selected_product'
    ) {
      return <ModelLightboxTitle attachment={currentLightboxImageAttachment} />;
    }
  }, [currentLightboxImageAttachment]);

  if (attachmentsTotalCount === 0) {
    return null;
  }

  return (
    <>
      <div
        className={cl('group mt-1 flex', {
          'flex-row-reverse': reverse,
          'mb-0.5': attachmentsTotalCount > 0
        })}
      >
        <div
          ref={setReferenceTooltipElement}
          className={cl('rounded-xl max-w-xl', {
            'rounded-tl': message.body && !reverse,
            'rounded-bl': attachmentsTotalCount > 0 && !reverse,
            'rounded-tr': message.body && reverse,
            'rounded-br': attachmentsTotalCount > 0 && reverse,
            'grid gap-1 w-full': attachmentsTotalCount > 1,
            'grid-cols-2':
              attachmentsTotalCount === 2 || attachmentsTotalCount === 4,
            'grid-cols-2 sm:grid-cols-3':
              attachmentsTotalCount === 3 ||
              attachmentsTotalCount === 5 ||
              attachmentsTotalCount === 6,
            'grid-cols-2 sm:grid-cols-4': attachmentsTotalCount > 6
          })}
        >
          {attachments.map((attachment) => (
            <ItemMessagesListMessageImage
              message={message}
              messagesCacheKey={messagesCacheKey}
              key={attachment.uuid}
              attachment={attachment}
              onLightboxOpen={handleLightboxOpenOnSlide}
              attachmentsTotalCount={attachmentsTotalCount}
              isVisibleForClient={isVisibleForClient}
              togglePreventModalClose={togglePreventModalClose}
            />
          ))}
        </div>
        <TooltipMessageDate
          referenceElement={referenceTooltipElement}
          date={message.createdAt}
        />
        {!message.body && (
          <ItemMessagesListMessageMenu
            message={message}
            messagesCacheKey={messagesCacheKey}
            forwardMessageProjectUuid={forwardMessageProjectUuid}
            forwardMessageTaskUuid={forwardMessageTaskUuid}
          />
        )}
      </div>

      <LightboxWrapper
        handleLightboxClose={handleLightboxClose}
        handleLightboxNext={handleLightboxNext}
        handleLightboxOpen={handleLightboxOpen}
        handleLightboxPrev={handleLightboxPrev}
        index={index}
        imageItem={imageItem}
        lightBoxOpened={lightBoxOpened}
        mainSrc={mainSrc}
        nextSrc={nextSrc}
        prevSrc={prevSrc}
        mainSrcThumbnail={mainSrcThumbnail}
        nextSrcThumbnail={nextSrcThumbnail}
        prevSrcThumbnail={prevSrcThumbnail}
        withFullScreenButton
        renderImageTitle={
          currentLightboxImageAttachment?.type !== 'file_attachment'
            ? renderImageTitle
            : undefined
        }
        withBaseImageTitle={
          currentLightboxImageAttachment?.type === 'file_attachment'
        }
        toggleImageEditorBackdrop={togglePreventModalClose}
        onImageEditSubmit={
          currentLightboxImageAttachment?.type === 'file_attachment' &&
          currentUser.hasPermissions(
            MessagesPermissions.READ_MESSAGE_FILE_ATTACHMENT_EDIT_BUTTON
          )
            ? handleImageEditorSubmitWithItemNanoId
            : undefined
        }
      />
    </>
  );
}

export default ItemMessagesListMessageImages;
