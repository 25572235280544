import React from 'react';
import isEmpty from 'lodash/isEmpty';
import cl from 'classnames';

import { ItemMessagesListCurrentUserMessageItem } from './ItemMessagesListCurrentUserMessage.types';
import {
  FetchMessagesCacheKey,
  MessageProjectUUID,
  MessageTaskUUID
} from '../../../../../messagesTypes';
import { TogglePreventModalCloseAction } from '../../../../../../../helpers/modals/modalsTypes';

import { ItemMessagesListMessageBody } from '../ItemMessagesListMessageBody';
import { ItemMessagesListMessageIdButtons } from '../ItemMessagesListMessageIdButtons';
import { ItemMessagesListMessageImages } from '../ItemMessagesListMessageImages';
import { ItemMessagesListMessageFiles } from '../ItemMessagesListMessageFiles';
import { ItemMessagesListMessageColors } from '../ItemMessagesListMessageColors';
import { ItemMessagesListMessageIcons } from '../ItemMessagesListMessageIcons';
import { ItemMessagesListMessageMenu } from '../ItemMessagesListMessageMenu';
import { ItemMessagesListMessageForwardedMessage } from '../ItemMessagesListMessageForwardedMessage';
import { ItemMessagesListMessageSendEmailNotificationButton } from '../ItemMessagesListMessageSendEmailNotificationButton';

import { TooltipSingletonSourceWrapper } from '../../../../../../../helpers/tooltips/TooltipSingletonSourceWrapper';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { MessagesPermissions } from '../../../../../messagesConstants';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

interface ItemMessagesListCurrentUserMessageProps {
  message: ItemMessagesListCurrentUserMessageItem;
  messagesCacheKey: FetchMessagesCacheKey;
  prevSameAuthor: boolean;
  nextSameAuthor: boolean;
  forwardMessageProjectUuid: MessageProjectUUID;
  forwardMessageTaskUuid?: MessageTaskUUID;
  togglePreventModalClose?: TogglePreventModalCloseAction;
}

function ItemMessagesListCurrentUserMessage({
  message,
  messagesCacheKey,
  prevSameAuthor,
  nextSameAuthor,
  forwardMessageProjectUuid,
  forwardMessageTaskUuid,
  togglePreventModalClose
}: ItemMessagesListCurrentUserMessageProps) {
  const withoutBody = isEmpty(message.body);

  return (
    <div
      id={`message-${message.uuid}`}
      className={cl('ml-12 sm:ml-16 mb-0.5 ', {
        'mt-5': !prevSameAuthor
      })}
    >
      <div className="flex">
        <div className="flex flex-col flex-1">
          <CheckPermissions action={MessagesPermissions.READ_MESSAGE_ID}>
            <div className="text-xs mb-0.5 ml-2 text-gray-600 dark:text-gray-400 flex justify-end">
              <span>{message.id}</span>
            </div>
          </CheckPermissions>
          {prevSameAuthor ? null : (
            <div className="text-right text-xs mb-1 ml-2 text-gray-600 dark:text-gray-400">
              <ItemMessagesListMessageIdButtons message={message} />
            </div>
          )}
          <div className="w-full">
            <CheckPermissions
              action={MessagesPermissions.READ_MESSAGE_FORWARD_MESSAGE}
            >
              {isEmpty(message.forwardedMessage) ? null : (
                <ItemMessagesListMessageForwardedMessage
                  message={message}
                  reverse
                />
              )}
            </CheckPermissions>
            {withoutBody ? null : (
              <div className="group flex justify-end">
                <div>
                  <ItemMessagesListMessageBody
                    reverse
                    message={message}
                    messagesCacheKey={messagesCacheKey}
                    prevSameAuthor={prevSameAuthor}
                    nextSameAuthor={nextSameAuthor}
                  />
                </div>

                <ItemMessagesListMessageMenu
                  message={message}
                  messagesCacheKey={messagesCacheKey}
                  isLeft
                  forwardMessageProjectUuid={forwardMessageProjectUuid}
                  forwardMessageTaskUuid={forwardMessageTaskUuid}
                />
              </div>
            )}
            <TooltipSingletonSourceWrapper
              placement={TooltipPlacement.TOP}
              withArrow
            >
              <ItemMessagesListMessageImages
                reverse
                message={message}
                messagesCacheKey={messagesCacheKey}
                forwardMessageProjectUuid={forwardMessageProjectUuid}
                forwardMessageTaskUuid={forwardMessageTaskUuid}
                togglePreventModalClose={togglePreventModalClose}
              />
              <ItemMessagesListMessageFiles
                reverse
                message={message}
                messagesCacheKey={messagesCacheKey}
                forwardMessageProjectUuid={forwardMessageProjectUuid}
                forwardMessageTaskUuid={forwardMessageTaskUuid}
              />
            </TooltipSingletonSourceWrapper>

            <ItemMessagesListMessageColors
              reverse
              message={message}
              messagesCacheKey={messagesCacheKey}
              forwardMessageProjectUuid={forwardMessageProjectUuid}
              forwardMessageTaskUuid={forwardMessageTaskUuid}
            />
            <ItemMessagesListMessageIcons
              reverse
              message={message}
              messagesCacheKey={messagesCacheKey}
            />

            {withoutBody ? (
              <ItemMessagesListMessageSendEmailNotificationButton
                message={message}
                messagesCacheKey={messagesCacheKey}
                className="mt-1 flex items-center flex-row-reverse"
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemMessagesListCurrentUserMessage;
