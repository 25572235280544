import React from 'react';

import { FetchMessagesCacheKey } from '../../../../../../../../../messagesTypes';
import { ItemMessagesListResultsMessageItem } from '../../../../ItemMessagesListResultsMessage.types';

import {
  TOGGLE_MESSAGE_IS_RESULT_QUERY,
  ToggleMessageIsResultRecordType
} from '../../../../../../../../../queries/toggleMessageIsResult.query';

import { useToggleMessageIsResult } from '../../../../../../../../../hooks/useToggleMessageIsResult';

import { PureButtonHelper } from '../../../../../../../../../../../helpers/buttons/PureButtonHelper';

import { resultsKeys } from '../../../../../../../../../../../locales/keys';

interface ItemMessagesListResultsMessageMenuDropdownIsResultButtonProps {
  messagesCacheKey: FetchMessagesCacheKey;
  message: ItemMessagesListResultsMessageItem;
}

function ItemMessagesListResultsMessageMenuDropdownIsResultButton({
  message,
  messagesCacheKey
}: ItemMessagesListResultsMessageMenuDropdownIsResultButtonProps) {
  const { toggleMessageIsResult, toggleMessageIsResultLoading } =
    useToggleMessageIsResult<ToggleMessageIsResultRecordType>({
      indexCacheKey: messagesCacheKey,
      query: TOGGLE_MESSAGE_IS_RESULT_QUERY
    });

  const handleToggleMessageVisibleForClient = () =>
    toggleMessageIsResult({ uuid: message.uuid });

  return (
    <PureButtonHelper
      className="text-left flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      disabled={toggleMessageIsResultLoading}
      i18nText={resultsKeys.cancel}
      onClick={handleToggleMessageVisibleForClient}
    />
  );
}

export default ItemMessagesListResultsMessageMenuDropdownIsResultButton;
