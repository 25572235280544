import cl from 'classnames';
import includes from 'lodash/includes';

import { ItemMessagesListInvoiceMessageHeader } from '../ItemMessagesListInvoiceMessageHeader';
import {
  ItemMessagesListInvoiceMessageHeaderButtons,
  ItemMessagesListInvoiceMessageHeaderButtonsMessage
} from '../ItemMessagesListInvoiceMessageHeaderButtons';
import { ItemMessagesListInvoiceMessageShortViewTotalsTable } from './components/ItemMessagesListInvoiceMessageShortViewTotalsTable';

import { ItemMessagesListInvoiceMessageItem } from '../../ItemMessagesListInvoiceMessage.types';
import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';

import { PayInvoiceModalButton } from '../../../../../../../../invoices/components/modalButton/PayInvoiceModalButton';

import { CheckPermissions } from '../../../../../../../../../helpers/CheckPermissions';
import { Loading } from '../../../../../../../../../helpers/Loading';
import { AlertMessage } from '../../../../../../../../../helpers/AlertMessage';

import { words } from '../../../../../../../../../locales/keys';

import { bgCrossedInvoiceStatuses } from '../../../../../../../../invoices/invoicesConstants';
import { MessagesPermissions } from '../../../../../../../messagesConstants';
import {
  InvoicePrepayment,
  InvoicePrepaymentAmount,
  InvoiceStatus
} from '../../../../../../../../invoices/invoicesTypes';

interface ItemMessagesListInvoiceMessageShortViewProps {
  isOpen: boolean;
  message: ItemMessagesListInvoiceMessageItem &
    ItemMessagesListInvoiceMessageHeaderButtonsMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  toggleIsOpen: () => void;
  invoiceStatus: InvoiceStatus;
  prepayment: InvoicePrepayment;
  prepaymentAmount: InvoicePrepaymentAmount;
  payments: number;
}

function ItemMessagesListInvoiceMessageShortView({
  isOpen,
  message,
  messagesCacheKey,
  toggleIsOpen,
  invoiceStatus,
  prepayment,
  prepaymentAmount,
  payments
}: ItemMessagesListInvoiceMessageShortViewProps) {
  return (
    <div
      className={cl('relative bg-gray-50 dark:bg-gray-850', {
        'bg-crossed': includes(
          bgCrossedInvoiceStatuses,
          message.invoice?.status
        )
      })}
    >
      <ItemMessagesListInvoiceMessageHeaderButtons
        isOpen={isOpen}
        message={message}
        messagesCacheKey={messagesCacheKey}
        toggleIsOpen={toggleIsOpen}
      />

      <div className="max-w-3xl mx-auto flex gap-6 px-6 py-8">
        <ItemMessagesListInvoiceMessageHeader message={message} />

        <div className="flex-1 self-end">
          <ItemMessagesListInvoiceMessageShortViewTotalsTable
            message={message}
            invoiceStatus={invoiceStatus}
            prepayment={prepayment}
            prepaymentAmount={prepaymentAmount}
            payments={payments}
          />
        </div>
      </div>

      {/* Pay */}
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_INVOICE_PAY_BUTTON}
      >
        <Loading
          loaded={message.bitInvoiceFetched}
          className="p-4 flex items-center justify-center gap-0.5"
        >
          {message.bitInvoice?.showPayCardButton && message.invoice?.nanoId && (
            <div className="p-4 flex items-center justify-center gap-0.5">
              <PayInvoiceModalButton
                invoiceNanoId={message.invoice?.nanoId}
                className="py-1.5 pl-3 pr-3 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-white bg-blue-500 hover:bg-blue-600 shadow-sm hover:shadow-md"
                i18nText={words.pay}
                disabled={message.bitInvoice?.disabledPayCardButton}
              />
            </div>
          )}
        </Loading>
        <AlertMessage message={message.bitInvoiceErrorMessage} />
      </CheckPermissions>
    </div>
  );
}

export default ItemMessagesListInvoiceMessageShortView;
