import compact from 'lodash/compact';

import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import { InvoiceStatuses } from '../../../../../../../../invoices/invoicesTypes';
import { ItemMessagesListInvoiceMessageHeaderButtonsMessage } from './ItemMessagesListInvoiceMessageHeaderButtons.types';
import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';

import { ChargebackInvoiceModalButton } from '../../../../../../../../invoices/components/modalButton/ChargebackInvoiceModalButton';
import { ConfirmPaymentInvoiceModalButton } from '../../../../../../../../invoices/components/modalButton/ConfirmPaymentInvoiceModalButton';
import { DownloadInvoiceButton } from '../../../../../../../../invoices/components/buttons/DownloadInvoiceButton';
import { SendToEmailInvoiceModalButton } from '../../../../../../../../invoices/components/modalButton/SendToEmailInvoiceModalButton';
import { VoidInvoiceModalButton } from '../../../../../../../../invoices/components/modalButton/VoidInvoiceModalButton';

import { CheckPermissions } from '../../../../../../../../../helpers/CheckPermissions';
import { DropdownHelper } from '../../../../../../../../../helpers/dropdowns/DropdownHelper';
import { PopoverPlacement } from '../../../../../../../../../helpers/Popover/popoverConstants';
import { PureIconButtonHelper } from '../../../../../../../../../helpers/buttons/PureIconButtonHelper';

import { MessagesPermissions } from '../../../../../../../messagesConstants';

import { invoicesKeys, words } from '../../../../../../../../../locales/keys';

interface ItemMessagesListInvoiceMessageHeaderButtonsProps {
  isOpen: boolean;
  message: ItemMessagesListInvoiceMessageHeaderButtonsMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  toggleIsOpen: () => void;
}

function ItemMessagesListInvoiceMessageHeaderButtons({
  isOpen,
  message,
  messagesCacheKey,
  toggleIsOpen
}: ItemMessagesListInvoiceMessageHeaderButtonsProps) {
  const bitInvoice = message.bitInvoice;

  const dropdownButtonsPermissions = compact([
    message.invoice?.status === InvoiceStatuses.PAID
      ? MessagesPermissions.READ_MESSAGE_INVOICE_CHARGEBACK_BUTTON
      : null,
    bitInvoice?.showVoidButton
      ? MessagesPermissions.READ_MESSAGE_INVOICE_VOID_BUTTON
      : null,
    bitInvoice?.showPayWireButton ||
    bitInvoice?.secondaryInvoice?.showPayWireButton
      ? MessagesPermissions.READ_MESSAGE_INVOICE_CONFIRM_PAYMENT_BUTTON
      : null
  ]);

  return (
    <div className="absolute -top-px -right-px flex -space-x-px">
      <div className="relative flex items-center justify-center">
        <CheckPermissions actions={dropdownButtonsPermissions}>
          <DropdownHelper
            buttonClassName="p-1.5 flex justify-center items-center border dark:border-gray-800 hover:bg-gray-200 dark:hover:bg-gray-800"
            dropdownPlacement={PopoverPlacement.BOTTOM_END}
            icon={IconsEnum.DOTS_HORIZONTAL_OUTLINE}
          >
            {/* Chargeback */}
            {message.invoice?.status === InvoiceStatuses.PAID && (
              <CheckPermissions
                action={
                  MessagesPermissions.READ_MESSAGE_INVOICE_CHARGEBACK_BUTTON
                }
              >
                <ChargebackInvoiceModalButton
                  invoiceId={message.invoiceId}
                  cacheKeys={[messagesCacheKey]}
                  i18nText={invoicesKeys.chargeback}
                />
              </CheckPermissions>
            )}

            {/* Void */}
            {bitInvoice?.showVoidButton && (
              <CheckPermissions
                action={MessagesPermissions.READ_MESSAGE_INVOICE_VOID_BUTTON}
              >
                <VoidInvoiceModalButton
                  disabled={bitInvoice.disabledVoidButton}
                  cacheKeys={[messagesCacheKey]}
                  i18nText={words.void}
                  invoiceId={message.invoiceId}
                />
              </CheckPermissions>
            )}

            {/* Confirm payment */}
            {(bitInvoice?.showPayWireButton ||
              bitInvoice?.secondaryInvoice?.showPayWireButton) && (
              <CheckPermissions
                action={
                  MessagesPermissions.READ_MESSAGE_INVOICE_CONFIRM_PAYMENT_BUTTON
                }
              >
                <ConfirmPaymentInvoiceModalButton
                  className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
                  i18nText={invoicesKeys.confirmPayment}
                  invoiceId={
                    bitInvoice.showPayWireButton
                      ? bitInvoice.id
                      : bitInvoice.secondaryInvoice?.id
                  }
                  invoiceAmount={
                    bitInvoice.showPayWireButton
                      ? bitInvoice.amount
                      : bitInvoice.secondaryInvoice?.amount
                  }
                  disabled={
                    bitInvoice.showPayWireButton
                      ? bitInvoice.disabledPayWireButton
                      : bitInvoice.secondaryInvoice?.disabledPayWireButton
                  }
                />
              </CheckPermissions>
            )}
          </DropdownHelper>
        </CheckPermissions>
      </div>

      {/* Mail */}
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_INVOICE_MAIL_BUTTON}
      >
        <SendToEmailInvoiceModalButton
          invoiceId={message.invoiceId}
          invoiceBillingInfo={bitInvoice?.invoiceBillingInfo}
          team={bitInvoice?.generalLedger?.company}
          className="p-1.5 flex justify-center items-center border dark:border-gray-800 hover:bg-gray-200 dark:hover:bg-gray-800"
          icon={IconsEnum.MAIL}
        />
      </CheckPermissions>

      {/* Download */}
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_INVOICE_DOWNLOAD_BUTTON}
      >
        <DownloadInvoiceButton
          className="p-1.5 flex justify-center items-center border dark:border-gray-800 hover:bg-gray-200 dark:hover:bg-gray-800"
          icon={IconsEnum.DOWNLOAD_SOLID}
          invoiceId={message.invoiceId}
        />
      </CheckPermissions>

      {/* Toggle */}
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_INVOICE_TOGGLE_BUTTON}
      >
        <PureIconButtonHelper
          className="p-1.5 flex justify-center items-center border dark:border-gray-800 hover:bg-gray-200 dark:hover:bg-gray-800"
          icon={isOpen ? IconsEnum.CHEVRON_UP : IconsEnum.CHEVRON_DOWN_SOLID}
          onClick={toggleIsOpen}
        />
      </CheckPermissions>
    </div>
  );
}

export default ItemMessagesListInvoiceMessageHeaderButtons;
