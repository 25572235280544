import {
  ItemMessagesListMessageImageFileAttachmentAttachment,
  ItemMessagesListMessageImageSelectedProductAttachment,
  ItemMessagesListMessageImageSelectedLifestyleAttachment,
  ItemMessagesListMessageImageSelectedMaterialAttachment
} from '../../index';

function mapMessageAttachmentImage(
  attachment:
    | ItemMessagesListMessageImageFileAttachmentAttachment
    | ItemMessagesListMessageImageSelectedProductAttachment
    | ItemMessagesListMessageImageSelectedLifestyleAttachment
    | ItemMessagesListMessageImageSelectedMaterialAttachment
) {
  switch (attachment.type) {
    case 'file_attachment':
      return attachment;
    case 'selected_product':
      return attachment.product?.image;
    case 'selected_lifestyle':
      return attachment.lifestyle?.image;
    case 'selected_material':
      return attachment.material?.image;
  }
}

export default mapMessageAttachmentImage;
