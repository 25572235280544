import React from 'react';

import { TogglePreventModalCloseAction } from '../../../../../helpers/modals/modalsTypes';

import {
  FetchMessagesCacheKey,
  MessageMessageTypes,
  MessageProjectUUID,
  MessageTaskUUID
} from '../../../messagesTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { ItemMessagesListBillMessage } from './components/ItemMessagesListBillMessage';
import { ItemMessagesListCurrentUserMessage } from './components/ItemMessagesListCurrentUserMessage';
import { ItemMessagesListDefaultMessage } from './components/ItemMessagesListDefaultMessage';
import { ItemMessagesListInvoiceActionMessage } from './components/ItemMessagesListInvoiceActionMessage';
import { ItemMessagesListInvoiceMessage } from './components/ItemMessagesListInvoiceMessage';
import { ItemMessagesListPaidBillMessage } from './components/ItemMessagesListPaidBillMessage';
import { ItemMessagesListResultsMessage } from './components/ItemMessagesListResultsMessage';
import { ItemMessagesListSendingMessage } from './components/ItemMessagesListSendingMessage';
import { ItemMessagesListTrackTimeMessage } from './components/ItemMessagesListTrackTimeMessage';

import {
  ItemMessagesListMessageItem,
  ItemMessagesListMessageOnRemoveSendingMessage
} from './ItemMessagesListMessage.types';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { MessagesPermissions } from '../../../messagesConstants';

interface ItemMessagesListMessageProps {
  message: ItemMessagesListMessageItem;
  messagesCacheKey: FetchMessagesCacheKey;
  prevSameAuthor?: boolean;
  nextSameAuthor?: boolean;
  forwardMessageProjectUuid: MessageProjectUUID;
  forwardMessageTaskUuid?: MessageTaskUUID;
  togglePreventModalClose?: TogglePreventModalCloseAction;
  onRemoveSendingMessage?: ItemMessagesListMessageOnRemoveSendingMessage;
}

function ItemMessagesListMessage({
  message,
  messagesCacheKey,
  prevSameAuthor,
  nextSameAuthor,
  forwardMessageProjectUuid,
  forwardMessageTaskUuid,
  togglePreventModalClose,
  onRemoveSendingMessage
}: ItemMessagesListMessageProps) {
  const currentUser = useCurrentUser();

  if (message.messageType === MessageMessageTypes.FRONTEND_SENDING_MESSAGE) {
    return (
      <ItemMessagesListSendingMessage
        message={message}
        onRemoveSendingMessage={onRemoveSendingMessage}
      />
    );
  }

  if (message.bill) {
    return (
      <CheckPermissions action={MessagesPermissions.READ_MESSAGE_BILL}>
        <ItemMessagesListBillMessage
          message={message}
          messagesCacheKey={messagesCacheKey}
          centered
        />
      </CheckPermissions>
    );
  }

  if (
    message.messageType === MessageMessageTypes.INVOICE_ACTION_MESSAGE &&
    message.invoice
  ) {
    return (
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_INVOICE_ACTION}
      >
        <ItemMessagesListInvoiceActionMessage
          message={message}
          messagesCacheKey={messagesCacheKey}
        />
      </CheckPermissions>
    );
  }

  if (message.invoice) {
    return (
      <CheckPermissions action={MessagesPermissions.READ_MESSAGE_INVOICE}>
        <ItemMessagesListInvoiceMessage
          message={message}
          messagesCacheKey={messagesCacheKey}
        />
      </CheckPermissions>
    );
  }

  if (message.messageType === MessageMessageTypes.PAID_MESSAGE) {
    return (
      <CheckPermissions action={MessagesPermissions.READ_MESSAGE_PAID_BILL}>
        <ItemMessagesListPaidBillMessage
          message={message}
          messagesCacheKey={messagesCacheKey}
        />
      </CheckPermissions>
    );
  }

  if (
    message.messageType === MessageMessageTypes.TRACK_TIME ||
    message.hours > 0 ||
    message.minutes > 0
  ) {
    return <ItemMessagesListTrackTimeMessage message={message} />;
  }

  if (message.isResult && message.task) {
    return (
      <ItemMessagesListResultsMessage
        togglePreventModalClose={togglePreventModalClose}
        messagesCacheKey={messagesCacheKey}
        message={message}
      />
    );
  }

  if (currentUser.uuid === message.user?.uuid) {
    return (
      <ItemMessagesListCurrentUserMessage
        message={message}
        messagesCacheKey={messagesCacheKey}
        prevSameAuthor={prevSameAuthor}
        nextSameAuthor={nextSameAuthor}
        forwardMessageProjectUuid={forwardMessageProjectUuid}
        forwardMessageTaskUuid={forwardMessageTaskUuid}
        togglePreventModalClose={togglePreventModalClose}
      />
    );
  }

  if (message.messageType === MessageMessageTypes.PAYMENT_PROCESSING) {
    return (
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_PAYMENT_PROCESSING}
      >
        <ItemMessagesListDefaultMessage
          message={message}
          messagesCacheKey={messagesCacheKey}
          prevSameAuthor={prevSameAuthor}
          nextSameAuthor={nextSameAuthor}
          forwardMessageProjectUuid={forwardMessageProjectUuid}
          forwardMessageTaskUuid={forwardMessageTaskUuid}
        />
      </CheckPermissions>
    );
  }

  return (
    <ItemMessagesListDefaultMessage
      message={message}
      messagesCacheKey={messagesCacheKey}
      prevSameAuthor={prevSameAuthor}
      nextSameAuthor={nextSameAuthor}
      forwardMessageProjectUuid={forwardMessageProjectUuid}
      forwardMessageTaskUuid={forwardMessageTaskUuid}
      togglePreventModalClose={togglePreventModalClose}
    />
  );
}

export default ItemMessagesListMessage;
