import React from 'react';
import cl from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { Icon } from '../../../../../../../helpers/Icon';
import { MarkdownHelper } from '../../../../../../../helpers/MarkdownHelper';
import { Translate } from '../../../../../../../helpers/Translate';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { ItemMessagesListMessageForwardedMessageMessage } from './ItemMessagesListMessageForwardedMessage.types';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { ItemMessagesListMessageForwardedMessageColors } from './components/ItemMessagesListMessageForwardedMessageColors';

import { MessagesPermissions } from '../../../../../messagesConstants';

import { messagesKeys } from '../../../../../../../locales/keys';

interface ItemMessagesListMessageForwardedMessageProps {
  message: ItemMessagesListMessageForwardedMessageMessage;
  reverse?: boolean;
}

function ItemMessagesListMessageForwardedMessage({
  message,
  reverse
}: ItemMessagesListMessageForwardedMessageProps) {
  const currentUser = useCurrentUser();

  if (!message.forwardedMessage) {
    return null;
  }

  return (
    <div
      className={cl(
        'opacity-75 z-0 relative hover:opacity-90 focus-within:opacity-90',
        {
          '-mb-4': isEmpty(message.forwardedMessage.colors)
        }
      )}
    >
      <div
        className={cl(reverse ? 'ml-12 sm:ml-16 mr-3' : 'mr-3 sm:mr-7 ml-3')}
      >
        <div
          className={cl('flex', {
            'justify-end': reverse
          })}
        >
          <div className="flex flex-col relative">
            <div
              className={cl(
                'text-xs mb-0.5 ml-2 text-gray-600 dark:text-gray-400',
                message.forwardedMessage.body ? 'mb-0.5' : 'pb-4'
              )}
            >
              <Icon
                icon={IconsEnum.ARROW_RIGHT_SOLID}
                className="w-3.5 h-3.5 inline mr-1"
              />
              <Translate id={messagesKeys.forwardedFrom} />{' '}
              {message.forwardedMessage.user?.fullName}
            </div>
            <div className="w-full">
              <div
                className={cl(
                  'group flex',
                  reverse ? 'justify-end' : 'justify-start'
                )}
              >
                <div className="flex flex-col items-end">
                  {isEmpty(message.forwardedMessage.body) ? null : (
                    <div
                      className={cl(
                        'inline-flex px-3 py-2 rounded-xl relative text-xs max-w-xl',
                        {
                          'border border-gray-200 dark:border-gray-800 rounded-bl':
                            !message.forwardedMessage.visibleForClient &&
                            !message.forwardedMessage.user?.client,
                          'bg-blue-100 dark:bg-gray-300 dark:text-black':
                            message.forwardedMessage.visibleForClient &&
                            !message.forwardedMessage.user?.client,
                          'bg-blue-100 border-blue-500 border-l-8 dark:text-black':
                            message.forwardedMessage.user?.client,
                          'pb-6': isEmpty(message.forwardedMessage.colors)
                        }
                      )}
                    >
                      <MarkdownHelper
                        source={message.forwardedMessage.body}
                        className="break-wrap markdown markdown-sm sm:markdown-md markdown-pre:whitespace-pre-wrap leading-snug"
                        fromMessage
                        linkTarget={
                          currentUser.hasPermissions(
                            MessagesPermissions.READ_MESSAGE_LINK_BLANK
                          )
                            ? '_blank'
                            : undefined
                        }
                      />
                    </div>
                  )}
                  {isEmpty(message.forwardedMessage.colors) ? null : (
                    <ItemMessagesListMessageForwardedMessageColors
                      message={message.forwardedMessage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemMessagesListMessageForwardedMessage;
