import React, { useState } from 'react';
import size from 'lodash/size';
import cl from 'classnames';

import { ItemMessagesListMessageColorsMessage } from './ItemMessagesListMessageColors.types';
import {
  FetchMessagesCacheKey,
  MessageProjectUUID,
  MessageTaskUUID
} from '../../../../../messagesTypes';

import { ColorButtons } from '../../../../../../../helpers/colors/ColorButtons';

import { ItemMessagesListMessageMenu } from '../ItemMessagesListMessageMenu';

import { TooltipMessageDate } from '../../../../tooltips/TooltipMessageDate';

interface ItemMessagesListMessageColorsProps {
  message: ItemMessagesListMessageColorsMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  reverse?: boolean;
  forwardMessageProjectUuid: MessageProjectUUID;
  forwardMessageTaskUuid?: MessageTaskUUID;
}

function ItemMessagesListMessageColors({
  message,
  messagesCacheKey,
  reverse,
  forwardMessageProjectUuid,
  forwardMessageTaskUuid
}: ItemMessagesListMessageColorsProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLDivElement | null>(null);

  const isVisibleForClient = !message.body && message.visibleForClient;

  if (size(message.colors) === 0) {
    return null;
  }

  return (
    <div
      className={cl('flex group mt-0.5', {
        'flex-row-reverse': reverse
      })}
    >
      <div
        ref={setReferenceTooltipElement}
        className={cl('text-sm overflow-hidden rounded-xl ', {
          'rounded-tr rounded-br': reverse,
          'rounded-tl rounded-bl': !reverse
        })}
      >
        <ColorButtons
          colors={message.colors}
          isVisibleForClient={isVisibleForClient}
        />
      </div>
      {!message.body && (
        <ItemMessagesListMessageMenu
          message={message}
          messagesCacheKey={messagesCacheKey}
          forwardMessageProjectUuid={forwardMessageProjectUuid}
          forwardMessageTaskUuid={forwardMessageTaskUuid}
        />
      )}
      <TooltipMessageDate
        referenceElement={referenceTooltipElement}
        date={message.createdAt}
      />
    </div>
  );
}

export default ItemMessagesListMessageColors;
