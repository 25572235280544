import { TaskNanoID } from '../../../tasks/tasksTypes';

import {
  CreateMessageInTaskGqlQuery,
  CreateMessageInTaskGqlStatus,
  MessageGqlError,
  MessageUUID,
  MessageBody,
  MessageTaskID,
  MessageVisibleForClient,
  MessageFileAttachmentIDs,
  MessageSelectedProductIDs,
  MessageSelectedLifestyleIDs,
  MessageColors
} from '../../messagesTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

import { TaskCache } from '../../../tasks/TaskCache';

export interface CreateMessageInTaskInput {
  uuid: MessageUUID;
  body: MessageBody;
  taskId: MessageTaskID;
  visibleForClient: MessageVisibleForClient;
  fileAttachmentIds?: MessageFileAttachmentIDs;
  attachedFileAttachmentIds?: MessageFileAttachmentIDs;
  selectedProductIds?: MessageSelectedProductIDs;
  selectedLifestyleIds?: MessageSelectedLifestyleIDs;
  colors?: MessageColors;
}

interface CreateMessageInTaskResponse<CreateMessageInTaskRecordType> {
  createMessageInTask: {
    status: CreateMessageInTaskGqlStatus;
    recordUuid: MessageUUID;
    record: CreateMessageInTaskRecordType;
    errors: CreateMessageInTaskErrors;
  };
}

interface CreateMessageInTaskErrors {
  done: MessageGqlError;
  fullMessages: MessageGqlError;
  name: MessageGqlError;
  taskId: MessageGqlError;
  visibleForClients: MessageGqlError;
}

interface CreateMessageInTaskOptions {
  taskNanoId: TaskNanoID;
  query: CreateMessageInTaskGqlQuery;
}

const action = 'createMessageInTask';

function useCreateMessageInTask<CreateMessageInTaskRecordType>({
  taskNanoId,
  query
}: CreateMessageInTaskOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateMessageInTaskInput,
    CreateMessageInTaskResponse<CreateMessageInTaskRecordType>,
    CreateMessageInTaskErrors,
    CreateMessageInTaskRecordType
  >({
    action,
    cacheKeys: [TaskCache.messagesCacheKey(taskNanoId)],
    query
  });

  return {
    createMessageInTaskData: createQueryData,
    createMessageInTaskError: createQueryError,
    createMessageInTaskLoading: createQueryLoading,
    createMessageInTaskErrorMessage: createQueryErrorMessage,
    createMessageInTask: createQuery,
    createMessageInTaskReset: createQueryReset
  };
}

export default useCreateMessageInTask;
