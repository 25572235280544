import React from 'react';

import { PositionStyleEnum } from '../../../../../../../../../../../types';
import { IconsEnum } from '../../../../../../../../../../../assets/icons/types';
import { TooltipPlacement } from '../../../../../../../../../../../helpers/tooltips/tooltipsConstants';
import { TaskNanoID } from '../../../../../../../../../../tasks/tasksTypes';
import { TogglePreventModalCloseAction } from '../../../../../../../../../../../helpers/modals/modalsTypes';
import { FetchMessagesCacheKey } from '../../../../../../../../../messagesTypes';
import { ItemMessagesListResultsMessageResultImageButtonsFileAttachment } from './ItemMessagesListResultsMessageResultImageButtons.types';

import { useCurrentUser } from '../../../../../../../../../../../auth/hooks/useAuth';

import { EditImageAndSendToTaskModalButton } from '../../../../../../../../../../tasks/components/modalButtons/EditImageAndSendToTaskModalButton';
import { DownloadFileAttachmentModalButton } from '../../../../../../../../../../fileAttachments/components/buttons/DownloadFileAttachmentModalButton';
import { DeleteFileAttachmentButton } from '../../../../../../../../../../fileAttachments/components/buttons/DeleteFileAttachmentButton';
import { SubmitSourceFilesToTaskModalButton } from '../../../../../../../../../../tasks/components/modalButtons/SubmitSourceFilesToTaskModalButton';

import { TooltipDropdownHelper } from '../../../../../../../../../../../helpers/dropdowns/TooltipDropdownHelper';
import { PopoverPlacement } from '../../../../../../../../../../../helpers/Popover/popoverConstants';
import { TooltipSingletonSourceWrapper } from '../../../../../../../../../../../helpers/tooltips/TooltipSingletonSourceWrapper';

import {
  tasksKeys,
  words
} from '../../../../../../../../../../../locales/keys';
import { MessagesPermissions } from '../../../../../../../../../messagesConstants';

interface ItemMessagesListResultsMessageResultImageButtonsProps {
  taskNanoId?: TaskNanoID;
  fileAttachment: ItemMessagesListResultsMessageResultImageButtonsFileAttachment;
  togglePreventModalClose?: TogglePreventModalCloseAction;
  messagesCacheKey: FetchMessagesCacheKey;
}

function ItemMessagesListResultsMessageResultImageButtons({
  taskNanoId,
  fileAttachment,
  messagesCacheKey
}: ItemMessagesListResultsMessageResultImageButtonsProps) {
  const currentUser = useCurrentUser();

  const withEditImageButton =
    taskNanoId &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_RESULT_EDIT_BUTTON
    );

  const withDownloadButton =
    fileAttachment.uuid &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_DOWNLOAD_FILE_ATTACHMENT_BUTTON
    );

  const withSubmitSourceFilesButton =
    taskNanoId &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_RESULT_SUBMIT_SOURCE_FILES_BUTTON
    );

  const withDeleteButton =
    fileAttachment.uuid &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_DELETE_FILE_ATTACHMENT_BUTTON
    );

  if (
    !withEditImageButton &&
    !withDownloadButton &&
    !withSubmitSourceFilesButton &&
    !withDeleteButton
  ) {
    return null;
  }

  return (
    <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 bg-white dark:bg-gray-800 rounded-md shadow p-0.5 flex gap-0.5">
      <TooltipSingletonSourceWrapper withArrow placement={TooltipPlacement.TOP}>
        {withDownloadButton && (
          <DownloadFileAttachmentModalButton
            fileAttachmentUuid={fileAttachment.uuid}
            className="py-1.5 pl-1.5 pr-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            icon={IconsEnum.DOWNLOAD_SOLID}
            tooltipI18nText={words.download}
            tooltipSingleton
          />
        )}
        {withEditImageButton && (
          <EditImageAndSendToTaskModalButton
            file={fileAttachment.file}
            taskNanoId={taskNanoId}
            className="py-1.5 pl-1.5 pr-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            icon={IconsEnum.PENCIL_ALT_OUTLINE}
            tooltipI18nText={words.edit}
            tooltipSingleton
          />
        )}

        {(withDeleteButton || withSubmitSourceFilesButton) && (
          <div className="relative">
            <TooltipDropdownHelper
              buttonClassName="py-1.5 pl-1.5 pr-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              icon={IconsEnum.DOTS_HORIZONTAL_OUTLINE}
              popoverPositionStyle={PositionStyleEnum.fixed}
              tooltipI18nText={words.more}
              dropdownPlacement={PopoverPlacement.BOTTOM_END}
              tooltipSingleton
            >
              {withSubmitSourceFilesButton && (
                <SubmitSourceFilesToTaskModalButton
                  taskNanoId={taskNanoId}
                  className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
                  i18nText={tasksKeys.submitAsSourceFile}
                  initialFile={fileAttachment}
                />
              )}

              {withDeleteButton && (
                <DeleteFileAttachmentButton
                  cacheKey={messagesCacheKey}
                  fileAttachmentUuid={fileAttachment.uuid}
                  className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
                  i18nText={words.remove}
                />
              )}
            </TooltipDropdownHelper>
          </div>
        )}
      </TooltipSingletonSourceWrapper>
    </div>
  );
}

export default ItemMessagesListResultsMessageResultImageButtons;
