import React, { useCallback } from 'react';
import cl from 'classnames';

import {
  ItemMessagesListMessageImageFileAttachmentAttachment,
  ItemMessagesListMessageImageSelectedProductAttachment,
  ItemMessagesListMessageImageSelectedLifestyleAttachment,
  ItemMessagesListMessageImageSelectedMaterialAttachment,
  ItemMessagesListMessageImageMessage,
  ItemMessagesListMessageImageHandleOpenLightbox
} from './ItemMessagesListMessageImage.types';
import { FetchMessagesCacheKey } from '../../../../../messagesTypes';

import { ItemMessagesListMessageImageFileAttachment } from '../ItemMessagesListMessageImageFileAttachment';
import { ItemMessagesListMessageImageLifestyle } from '../ItemMessagesListMessageImageLifestyle';
import { ItemMessagesListMessageImageMaterial } from '../ItemMessagesListMessageImageMaterial';
import { ItemMessagesListMessageImageProduct } from '../ItemMessagesListMessageImageProduct';

import { OnLightboxOpenAction } from '../../../../../../../helpers/LightboxWrapper';
import { TogglePreventModalCloseAction } from '../../../../../../../helpers/modals/modalsTypes';

import { Files } from '../../../../../../../utils/Files';
import { mapMessageAttachmentImage } from './utils/mapMessageAttachmentImage';

interface ItemMessagesListMessageImageProps {
  attachmentsTotalCount: number;
  isVisibleForClient: boolean;
  message: ItemMessagesListMessageImageMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  attachment:
    | ItemMessagesListMessageImageFileAttachmentAttachment
    | ItemMessagesListMessageImageSelectedProductAttachment
    | ItemMessagesListMessageImageSelectedLifestyleAttachment
    | ItemMessagesListMessageImageSelectedMaterialAttachment;
  onLightboxOpen: OnLightboxOpenAction;
  togglePreventModalClose?: TogglePreventModalCloseAction;
}

function ItemMessagesListMessageImage({
  message,
  messagesCacheKey,
  attachment,
  attachmentsTotalCount,
  isVisibleForClient,
  onLightboxOpen,
  togglePreventModalClose
}: ItemMessagesListMessageImageProps) {
  const handleOpenLightbox =
    useCallback<ItemMessagesListMessageImageHandleOpenLightbox>(
      (e) => {
        const lightboxImage = mapMessageAttachmentImage(attachment);
        if (lightboxImage && Files.isImage(lightboxImage?.file)) {
          e.preventDefault();
          onLightboxOpen(lightboxImage);
        }
      },
      [attachment, onLightboxOpen]
    );

  const imageClassName = cl(
    'object-cover bg-gray-100 dark:bg-gray-850',
    attachmentsTotalCount > 1 ? 'absolute inset-0 rounded ' : 'h-64'
  );

  const linkClassName = cl(
    'block focus:ring-base overflow-hidden focus:ring-offset-0 rounded relative',
    attachmentsTotalCount > 1
      ? 'aspect-w-1 aspect-h-1 h-auto w-full aspect-square'
      : null
  );

  if (attachment.type === 'file_attachment') {
    if (!Files.isImage(attachment.file)) {
      return null;
    }

    return (
      <ItemMessagesListMessageImageFileAttachment
        message={message}
        messagesCacheKey={messagesCacheKey}
        linkClassName={linkClassName}
        imageClassName={imageClassName}
        attachment={attachment}
        isVisibleForClient={isVisibleForClient}
        handleOpenLightbox={handleOpenLightbox}
        togglePreventModalClose={togglePreventModalClose}
      />
    );
  }

  if (
    attachment.type === 'selected_product' &&
    attachment.product &&
    attachment.product.image
  ) {
    return (
      <ItemMessagesListMessageImageProduct
        messagesCacheKey={messagesCacheKey}
        linkClassName={linkClassName}
        imageClassName={imageClassName}
        attachment={attachment}
        isVisibleForClient={isVisibleForClient}
        handleOpenLightbox={handleOpenLightbox}
      />
    );
  }

  if (
    attachment.type === 'selected_lifestyle' &&
    attachment.lifestyle &&
    attachment.lifestyle.image
  ) {
    return (
      <ItemMessagesListMessageImageLifestyle
        messagesCacheKey={messagesCacheKey}
        linkClassName={linkClassName}
        imageClassName={imageClassName}
        attachment={attachment}
        isVisibleForClient={isVisibleForClient}
        handleOpenLightbox={handleOpenLightbox}
      />
    );
  }

  if (
    attachment.type === 'selected_material' &&
    attachment.material &&
    attachment.material.image
  ) {
    return (
      <ItemMessagesListMessageImageMaterial
        messagesCacheKey={messagesCacheKey}
        linkClassName={linkClassName}
        imageClassName={imageClassName}
        attachment={attachment}
        isVisibleForClient={isVisibleForClient}
        handleOpenLightbox={handleOpenLightbox}
      />
    );
  }

  return null;
}

export default ItemMessagesListMessageImage;
