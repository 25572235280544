import React from 'react';
import cl from 'classnames';

import { IconsEnum } from '../../../../../../../../../../../assets/icons/types';
import { IsDisabled } from '../../../../../../../../../../../types';
import { ItemMessagesListResultsMessageContentButtonTask } from './ItemMessagesListResultsMessageContentButton.types';
import { ItemMessagesListResultsMessageContentButtonTypes } from '../../ItemMessagesListResultsMessageContent.types';

import { DownloadTaskResultsModalButton } from '../../../../../../../../../../tasks/components/buttons/DownloadTaskResultsModalButton';
import { RevisionTaskModalButton } from '../../../../../../../../../../tasks/components/modalButtons/RevisionTaskModalButton';
import { SubmitSourceFilesToTaskModalButton } from '../../../../../../../../../../tasks/components/modalButtons/SubmitSourceFilesToTaskModalButton';

import { PureIconButtonHelper } from '../../../../../../../../../../../helpers/buttons/PureIconButtonHelper';

import {
  resultsKeys,
  tasksKeys
} from '../../../../../../../../../../../locales/keys';

interface ItemMessagesListResultsMessageContentButtonProps {
  main?: boolean;
  color?: 'blue' | 'purple' | 'green';
  rounded?: boolean;
  buttonType: ItemMessagesListResultsMessageContentButtonTypes;
  task: ItemMessagesListResultsMessageContentButtonTask;
  onClick?: () => void;
  refetchAfterSubmit?: () => void;
  disabled?: IsDisabled;
}

function ItemMessagesListResultsMessageContentButton({
  main = false,
  rounded = false,
  color,
  buttonType,
  task,
  disabled,
  onClick,
  refetchAfterSubmit
}: ItemMessagesListResultsMessageContentButtonProps) {
  const className = main
    ? cl(
        'relative inline-flex items-center px-4 py-2 rounded-l-md text-sm font-medium focus:z-10 text-white gap-1',
        {
          'bg-blue-500 hover:bg-blue-600': color === 'blue',
          'bg-purple-500 hover:bg-purple-600': color === 'purple',
          'bg-green-500 hover:bg-green-600': color === 'green',
          'rounded-r-md': rounded
        }
      )
    : 'flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap';

  const iconClassName = main ? 'h-5 w-5' : 'h-5 w-5 stroke-2 mr-2';

  switch (buttonType) {
    case ItemMessagesListResultsMessageContentButtonTypes.REVISION:
      return (
        <RevisionTaskModalButton
          className={className}
          icon={main ? IconsEnum.REPLY_SOLID : IconsEnum.REPLY_OUTLINE}
          iconClassName={iconClassName}
          taskNanoId={task.nanoId}
          afterSubmit={refetchAfterSubmit}
        />
      );

    case ItemMessagesListResultsMessageContentButtonTypes.APPROVE_RESULTS:
      return (
        <PureIconButtonHelper
          className={className}
          icon={
            main ? IconsEnum.SHIELD_CHECK_SOLID : IconsEnum.SHIELD_CHECK_OUTLINE
          }
          iconClassName={iconClassName}
          i18nText={tasksKeys.buttons.approve}
          onClick={onClick}
          disabled={disabled}
        />
      );

    case ItemMessagesListResultsMessageContentButtonTypes.ACCEPT_RESULTS:
      return (
        <PureIconButtonHelper
          className={className}
          icon={main ? IconsEnum.ROCKET_SOLID : IconsEnum.ROCKET_OUTLINE}
          iconClassName={iconClassName}
          i18nText={tasksKeys.buttons.accept}
          onClick={onClick}
          disabled={disabled}
        />
      );

    case ItemMessagesListResultsMessageContentButtonTypes.DOWNLOAD_RESULTS:
      return (
        <DownloadTaskResultsModalButton
          className={className}
          icon={main ? IconsEnum.DOWNLOAD_SOLID : IconsEnum.DOWNLOAD_OUTLINE}
          iconClassName={iconClassName}
          i18nText={resultsKeys.download}
          taskNanoId={task.nanoId}
        />
      );

    case ItemMessagesListResultsMessageContentButtonTypes.SUBMIT_SOURCE_FILES:
      return (
        <SubmitSourceFilesToTaskModalButton
          className={className}
          icon={
            main ? IconsEnum.CLOUD_UPLOAD_SOLID : IconsEnum.CLOUD_UPLOAD_OUTLINE
          }
          iconClassName={iconClassName}
          taskNanoId={task.nanoId}
          i18nText={tasksKeys.submitSourceFiles}
        />
      );

    default:
      return null;
  }
}

export default ItemMessagesListResultsMessageContentButton;
