import includes from 'lodash/includes';

import { ItemMessagesListInvoiceMessageItem } from '../../../../ItemMessagesListInvoiceMessage.types';
import {
  InvoicePrepayment,
  InvoicePrepaymentAmount,
  InvoiceStatus,
  InvoiceStatuses
} from '../../../../../../../../../../invoices/invoicesTypes';

import { Translate } from '../../../../../../../../../../../helpers/Translate';
import { MoneyHelper } from '../../../../../../../../../../../helpers/MoneyHelper';
import { PercentHelper } from '../../../../../../../../../../../helpers/PercentHelper';

import { paidInvoiceStatuses } from '../../../../../../../../../../invoices/invoicesConstants';
import {
  formsFields,
  invoicesKeys,
  words
} from '../../../../../../../../../../../locales/keys';

interface ItemMessagesListInvoiceMessageShortViewTotalsTableProps {
  message: ItemMessagesListInvoiceMessageItem;
  invoiceStatus: InvoiceStatus;
  prepayment: InvoicePrepayment;
  prepaymentAmount: InvoicePrepaymentAmount;
  payments: number;
}
function ItemMessagesListInvoiceMessageShortViewTotalsTable({
  message,
  invoiceStatus,
  prepayment,
  prepaymentAmount,
  payments
}: ItemMessagesListInvoiceMessageShortViewTotalsTableProps) {
  const invoice = message?.bitInvoice;

  const invoiceTotal = message.invoice?.invoiceTotal || 0;

  const amountDue =
    invoiceStatus === InvoiceStatuses.PAID
      ? invoiceTotal - payments
      : invoice?.amount || 0;

  return (
    <table className="w-full">
      <tbody>
        <tr>
          <td></td>
          <td colSpan={2} className="text-xs text-left px-2 pb-0.5 pt-2">
            <Translate id={invoicesKeys.total} />
          </td>
          <td className="text-xs text-right pl-2 pb-0.5 pt-2">
            <MoneyHelper value={invoiceTotal} />
          </td>
        </tr>

        {includes(paidInvoiceStatuses, invoiceStatus) ? (
          <>
            <tr>
              <td></td>
              <td colSpan={2} className="text-xs text-left px-2">
                <Translate id={words.payments} />
              </td>
              <td className="text-xs text-right pl-2">
                <MoneyHelper value={-payments} />
              </td>
            </tr>
          </>
        ) : (
          <>
            <tr>
              <td></td>
              <td colSpan={2} className="text-xs text-left px-2">
                <Translate id={formsFields.prepayment} />
              </td>
              <td className="text-xs text-right pl-2">
                <PercentHelper percent={prepayment} />
              </td>
            </tr>
            <tr>
              <td></td>
              <td colSpan={2} className="text-xs pb-2 text-left px-2">
                <Translate id={invoicesKeys.prepaymentAmount} />
              </td>
              <td className="text-xs text-right pl-2 pb-2">
                <MoneyHelper value={prepaymentAmount} />
              </td>
            </tr>
          </>
        )}
        <tr>
          <td></td>
          <td
            colSpan={2}
            className="sm:text-lg font-semibold text-left px-2 py-2 border-t border-gray-200 dark:border-gray-600"
          >
            <Translate id={words.amountDue} />
          </td>
          <td className="sm:text-lg text-right pl-0.5 sm:pl-2 font-semibold border-t border-gray-200 dark:border-gray-600 py-1.5">
            <div className="inline-block py-0.5 px-0.5 sm:px-2 bg-yellow-100 dark:bg-yellow-800 rounded-md">
              <MoneyHelper value={amountDue} />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default ItemMessagesListInvoiceMessageShortViewTotalsTable;
